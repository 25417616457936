<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i>
                            <span class="h5"> Registro plan de auditoría stage 2</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <b-tabs v-model="tabIndex" variant="pills">

                                        <validation-observer ref="observer" v-if="disabledNorma1" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                                <b-tab :title-item-class="(datosPlanAuditoria.idPlanAuditoria == '' && !isTemplate) ?  'disabledTab' : ''">
                                                    <template slot="title"> <i class="fas fa-cog fa-md"></i> ISO 9001 </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty mediun responsive outlined>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="60%" class="text-center" colspan="3" rowspan="2">
                                                                                <b>ISO 9001: 2015 Sistemas de gestión de calidad</b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" rowspan="2">
                                                                                Dia / Fecha
                                                                            </b-td>
                                                                            <b-td width="20%" class="text-center" colspan="2">
                                                                                Hora
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" colspan="1" rowspan="2">
                                                                                Auditor
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="10%" class="text-center">
                                                                                SOY
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                PM
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-group label="Reunión de apertura realizada en:">
                                                                                    <b-form-checkbox-group plain v-model="datosPlanAuditoria.c9001[0].header[0].check" :options="datosPlanAuditoria.c9001[0].header[0].options" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[0].input1  = $event" :value="datosPlanAuditoria.c9001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[0].input2  = $event" :value="datosPlanAuditoria.c9001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[0].input3  = $event" :value="datosPlanAuditoria.c9001[0].header[0].input3" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[0].input4  = $event" :value="datosPlanAuditoria.c9001[0].header[0].input4" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[1].input1  = $event" :value="datosPlanAuditoria.c9001[0].header[1].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td colspan="4" class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[1].input2  = $event" :value="datosPlanAuditoria.c9001[0].header[1].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>

                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[2].input1  = $event" :value="datosPlanAuditoria.c9001[0].header[2].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td colspan="4" class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c9001[0].header[2].input2  = $event" :value="datosPlanAuditoria.c9001[0].header[2].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="data in datosPlanAuditoria.c9001[0].body" :key="data.index">
                                                                            <template v-if="data.total">
                                                                                <b-td v-if="data.hasLabel" :rowspan="data.size" colspan="7" class="valign-middle text-center">
                                                                                    <span>{{data.label}}</span>
                                                                                </b-td>
                                                                            </template>
                                                                            <template v-else>
                                                                                <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                                    <template v-if="data.categoriaVacia">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                                        <b-form-checkbox-group v-if="data.select" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                                        <b-form-group v-if="!data.sinauditado" label="Auditado:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </template>
                                                                                </b-td>
                                                                                <b-td :colspan="data.hasSubCheck ? '6' : '2'" class="text-center">
                                                                                    <div v-if="data.hasSubTexto">
                                                                                        <b-form-group :label="`${data.label1}:`" class="mb-2">
                                                                                            <b-form-input :placeholder="`Ingrese ${data.placeholder1}`" :value="data.texto1" @change="data.texto1 = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                        <b-form-group :label="`${data.label2}:`" class="mb-2">
                                                                                            <b-form-input :placeholder="`Ingrese ${data.placeholder2}`" :value="data.texto2" @change="data.texto2 = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasTexto">
                                                                                        <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheckGroup">
                                                                                        <b>Reunión de clausura en </b>
                                                                                        <b-form-checkbox-group plain v-model="data.checkGroup" :options="data.options">y asistir por el auditado y el equipo de auditoría</b-form-checkbox-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheck">
                                                                                        <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                            {{data.labelSubCheck}}
                                                                                        </b-form-checkbox>
                                                                                    </div>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <b>Nota:</b>
                                                                                <ol>
                                                                                    <li>
                                                                                        <p>El auditor se referirá a las normas aplicables, siempre que sea necesario para revisar los requisitos.</p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Los auditores deben asegurarse de que el requisito del alcance de la certificación y el estándar contractual se verifique durante la auditoría en base a los registros de la planta de trabajo o de los registros anteriores de la planta de trabajo para lograr los objetivos de la auditoría independientemente de la conclusión de la auditoría. </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Programa de auditoría de vigilancia para SA-I y SA-II se detalla en el Informe IA-RC-Stage-II.
                                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.c9001[0].footer[0].check2" :options="datosPlanAuditoria.c9001[0].footer[0].options" />
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>El programa de auditoría de vigilancia se ha planificado en general teniendo en cuenta lasTamaño de la organización del cliente / Alcance / Complejidad del sistema de gestión, productos y procesos y nivel demostrado de eficacia del sistema de gestión y los resultados de las auditorías anteriores.</p>
                                                                                        <span>El Programa de Auditoría de Vigilancia puede revisarse considerando lo siguiente. (ISO 17021-I 2015 Cl 9.1.3)</span>
                                                                                        <ol type="a">
                                                                                            <li>
                                                                                                <p>Quejas recibidas por IQC sobre el cliente / Cambios en los requisitos de certificación; </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Cambios en los requisitos legales / Cambios en los requisitos de acreditación;</p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Datos de desempeño organizacional (por ejemplo, niveles de defectos, datos de indicadores clave de desempeño); </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Preocupaciones de las partes interesadas </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Auditoría de vigilancia no coordinada según las condiciones del certificado de cumplimiento </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Se pueden considerar otros procesos para la auditoría con base en las observaciones de la auditoría.</p>
                                                                                            </li>
                                                                                        </ol>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Confidencialidad: Toda la información obtenida durante la ejecución de los servicios de Certificación se mantendrá como información confidencial y no se comunicará a ninguna persona o institución externa a menos que así lo requieran los sistemas legales locales y su aprobación.
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Responsabilidad: La responsabilidad de Integrated Quality Certification Pvt Ltd se limita al alcance de los servicios de certificación y términos y condiciones del acuerdo de certificación.
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>IQC solo es responsable de identificar el equipo de auditoría y delegar a los auditores competentes para evaluar los sistemas de gestión basados en los criterios de competencia según ISO 17021 y proporcionar recomendaciones para la certificación basadas en evidencia
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Los siguientes procesos también deben ser auditados en cada función durante la etapa II de la auditoría. Información documentada / Retención de información documentada / Rol, responsabilidad y autoridad / Competencia / Infraestructura / Objetivos / Auditoría interna NC y acción correctiva / Mejora desde la última auditoría, según corresponda
                                                                                        </p>
                                                                                    </li>
                                                                                </ol>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                Confirmado que el Plan de Auditoría de Vigilancia para se ha preparado según el Programa de Auditoría de Vigilancia
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.c9001[0].footer[0].check" :options="datosPlanAuditoria.c9001[0].footer[0].options" />
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2" class="text-center">
                                                                                <b-form-group label="Elaborado por:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c9001[0].footer[0].elaboradoPor" @change="datosPlanAuditoria.c9001[0].footer[0].elaboradoPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="5" class="text-center">
                                                                                <b-form-group label="Revisado por Auditor:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c9001[0].footer[0].revisadorPor" @change="datosPlanAuditoria.c9001[0].footer[0].revisadorPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer1" v-if="disabledNorma3" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                                <b-tab :title-item-class="(datosPlanAuditoria.idPlanAuditoria == '' && !isTemplate) ?  'disabledTab' : ''">
                                                    <template slot="title"> <i class="fas fa-cog fa-md"></i> ISO 45001 </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty mediun responsive outlined>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="60%" class="text-center" colspan="3" rowspan="2">
                                                                                <b>ISO 45001: 2018 -Sistemas de gestión de salud y ocupacional</b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" rowspan="2">
                                                                                Dia / Fecha
                                                                            </b-td>
                                                                            <b-td width="20%" class="text-center" colspan="2">
                                                                                Tiempo
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" colspan="1" rowspan="2">
                                                                                Auditor
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="10%" class="text-center">
                                                                                SOY
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                PM
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-group label="Reunión de apertura realizada en:">
                                                                                    <b-form-checkbox-group plain v-model="datosPlanAuditoria.c45001[0].header[0].check" :options="datosPlanAuditoria.c45001[0].header[0].options" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c45001[0].header[0].input1  = $event" :value="datosPlanAuditoria.c45001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c45001[0].header[0].input2  = $event" :value="datosPlanAuditoria.c45001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c45001[0].header[0].input3  = $event" :value="datosPlanAuditoria.c45001[0].header[0].input3" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c45001[0].header[0].input4  = $event" :value="datosPlanAuditoria.c45001[0].header[0].input4" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="data in datosPlanAuditoria.c45001[0].body" :key="data.index">
                                                                            <template v-if="data.total">
                                                                                <b-td v-if="data.hasLabel" :rowspan="data.size" colspan="7" class="valign-middle text-center">
                                                                                    <span>{{data.label}}</span>
                                                                                </b-td>
                                                                            </template>
                                                                            <template v-else>
                                                                                <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                                    <template v-if="data.categoriaVacia">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                                        <b-form-checkbox-group v-if="data.select" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                                    </template>
                                                                                </b-td>
                                                                                <b-td :colspan="data.hasSubCheck ? '6' : '2'" class="text-center">
                                                                                    <div v-if="data.hasTexto">
                                                                                        <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheckGroup">
                                                                                        <b>Reunión de clausura en </b>
                                                                                        <b-form-checkbox-group plain v-model="data.checkGroup" :options="data.options">y asistir por el auditado y el equipo de auditoría</b-form-checkbox-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheck">
                                                                                        <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                            {{data.labelSubCheck}}
                                                                                        </b-form-checkbox>
                                                                                    </div>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Programa de auditoría según informe de evaluación :</b>puede revisarse considerando cambios en los siguientes requisitos de certificación (ISO 17021-1: 2015 Cl 9.1.3). </p>
                                                                                <p><b>Los detalles de la información, como se muestra a continuación, serán proporcionados por IQC Operation para una posible revisión del programa de auditoría por parte del Líder del equipo de auditoría </b></p>
                                                                                <ul>
                                                                                    <li> Quejas recibidas por IQC sobre el cliente: <b-form-radio-group v-model="datosPlanAuditoria.c45001[0].footer[0].radio1" plain :options="[{ text: 'SI', value: 1 },{ text: 'Nulo', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de certificación: <b-form-radio-group v-model="datosPlanAuditoria.c45001[0].footer[0].radio2" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de acreditación: <b-form-radio-group v-model="datosPlanAuditoria.c45001[0].footer[0].radio3" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                </ul>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Confidencialidad:</b>Toda la información obtenida durante la ejecución de los servicios de Certificación se mantendrá como información confidencial y no se comunicará a ninguna persona o institución externa a menos que así lo requieran los sistemas legales locales y su aprobación. </p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Responsabilidad:</b> La responsabilidad de Integrated Quality Certification Private Limited se limita al alcance de los servicios de certificación y los términos y condiciones del acuerdo de certificación.</p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                Confirmado que el Plan de Auditoría de Vigilancia se ha preparado según el Programa de Auditoría de Vigilancia, incluida cualquier revisión del programa de auditoría
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.c45001[0].footer[0].check" :options="datosPlanAuditoria.c45001[0].footer[0].options" />
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2" class="text-center">
                                                                                <b-form-group label="Elaborado por:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c45001[0].footer[0].elaboradoPor" @change="datosPlanAuditoria.c45001[0].footer[0].elaboradoPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="5" class="text-center">
                                                                                <b-form-group label="Revisado por Auditor:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c45001[0].footer[0].revisadorPor" @change="datosPlanAuditoria.c45001[0].footer[0].revisadorPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer2" v-if="disabledNorma2" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                                <b-tab :title-item-class="(datosPlanAuditoria.idPlanAuditoria == '' && !isTemplate) ?  'disabledTab' : ''">
                                                    <template slot="title"> <i class="fas fa-cog fa-md"></i> ISO 14001 </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty mediun responsive outlined>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="60%" class="text-center" colspan="3" rowspan="1">
                                                                                <b>ISO 14001: 2015 EMS - ISO 45001: 2018 </b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" rowspan="2">
                                                                                Dia / Fecha
                                                                            </b-td>
                                                                            <b-td width="20%" class="text-center" colspan="2">
                                                                                Tiempo
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" colspan="1" rowspan="2">
                                                                                Auditor
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="10%" class="text-center" colspan="3">
                                                                                <b>Función y procesos de la organización</b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                SOY
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                PM
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-group label="Reunión de apertura realizada en:">
                                                                                    <b-form-checkbox-group plain v-model="datosPlanAuditoria.c14001[0].header[0].check" :options="datosPlanAuditoria.c14001[0].header[0].options" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c14001[0].header[0].input1  = $event" :value="datosPlanAuditoria.c14001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c14001[0].header[0].input2  = $event" :value="datosPlanAuditoria.c14001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c14001[0].header[0].input3  = $event" :value="datosPlanAuditoria.c14001[0].header[0].input3" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c14001[0].header[0].input4  = $event" :value="datosPlanAuditoria.c14001[0].header[0].input4" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="data in datosPlanAuditoria.c14001[0].body" :key="data.index">
                                                                            <template v-if="data.total">
                                                                                <b-td v-if="data.hasLabel" :rowspan="data.size" colspan="7" class="valign-middle text-center">
                                                                                    <span>{{data.label}}</span>
                                                                                </b-td>

                                                                            </template>
                                                                            <template v-else>
                                                                                <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                                    <template v-if="data.categoriaVacia">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                                        <b-form-checkbox-group v-if="data.select" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                                        <b-form-group v-if="!data.sinauditado" label="Auditado:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </template>
                                                                                </b-td>
                                                                                <b-td :colspan="data.hasSubCheck ? '6' : '2'">
                                                                                    <div v-if="data.hasTexto">
                                                                                        <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheckGroup">
                                                                                        <b>Reunión de clausura en </b>
                                                                                        <b-form-checkbox-group plain v-model="data.checkGroup" :options="data.options">y asistir por el auditado y el equipo de auditoría</b-form-checkbox-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheck">
                                                                                        <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                            {{data.labelSubCheck}}
                                                                                        </b-form-checkbox>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasCustom1">
                                                                                        <span>2.19. Análisis y evaluación de datos apropiados y información - </span>
                                                                                        <ol type="a">
                                                                                            <li>Implementación efectiva de sistemas de gestión QHSE</li>
                                                                                            <li>Número de incidentes, frecuencia de incidentes y tendencia</li>
                                                                                            <li>Porcentaje de acciones correctivas completadas a tiempo</li>
                                                                                            <li>Autoridades legales y reclamación de clientes</li>
                                                                                            <li>Cumplimiento de requisitos legales y de otro tipo.</li>
                                                                                            <li>Alcanzar los objetivos de QHSE</li>
                                                                                            <li>Efectividad de las acciones tomadas para abordar riesgos y oportunidades
                                                                                                <b-form-checkbox v-model="data.check1" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Desempeño de proveedores
                                                                                                <b-form-checkbox v-model="data.check2" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Necesidad de mejoras en el sistema de gestión de la calidad
                                                                                                <b-form-checkbox v-model="data.check3" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Conformidad de productos y servicios
                                                                                                <b-form-checkbox v-model="data.check4" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Grado de satisfacción del cliente
                                                                                                <b-form-checkbox v-model="data.check5" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                        </ol>
                                                                                    </div>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Programa de auditoría según informe de evaluación :</b>puede revisarse considerando cambios en los siguientes requisitos de certificación (ISO 17021-1: 2015 Cl 9.1.3). </p>
                                                                                <p><b>Los detalles de la información, como se muestra a continuación, serán proporcionados por IQC Operation para una posible revisión del programa de auditoría por parte del Líder del equipo de auditoría </b></p>
                                                                                <ul>
                                                                                    <li> Quejas recibidas por IQC sobre el cliente: <b-form-radio-group v-model="datosPlanAuditoria.c14001[0].footer[0].radio1" plain :options="[{ text: 'SI', value: 1 },{ text: 'Nulo', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de certificación: <b-form-radio-group v-model="datosPlanAuditoria.c14001[0].footer[0].radio2" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de acreditación: <b-form-radio-group v-model="datosPlanAuditoria.c14001[0].footer[0].radio3" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                </ul>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Confidencialidad:</b>Toda la información obtenida durante la ejecución de los servicios de Certificación se mantendrá como información confidencial y no se comunicará a ninguna persona o institución externa a menos que así lo requieran los sistemas legales locales y su aprobación. </p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Responsabilidad:</b> La responsabilidad de Integrated Quality Certification Private Limited se limita al alcance de los servicios de certificación y los términos y condiciones del acuerdo de certificación.</p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                <span>El Plan de Auditoría se ha elaborado considerando el programa de auditoría y la revisión aplicable.</span>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                Confirmado que el Plan de Auditoría de Vigilancia se ha preparado según el Programa de Auditoría de Vigilancia, incluida cualquier revisión del programa de auditoría
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.c14001[0].footer[0].check" :options="datosPlanAuditoria.c14001[0].footer[0].options" />
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2" class="text-center">
                                                                                <b-form-group label="Elaborado por:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c14001[0].footer[0].elaboradoPor" @change="datosPlanAuditoria.c14001[0].footer[0].elaboradoPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="5" class="text-center">
                                                                                <b-form-group label="Revisado por Auditor:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c14001[0].footer[0].revisadorPor" @change="datosPlanAuditoria.c14001[0].footer[0].revisadorPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer3" v-if="disabledNorma5 || disabledNorma6" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                                <b-tab :title-item-class="(datosPlanAuditoria.idPlanAuditoria == '' && !isTemplate) ?  'disabledTab' : ''">
                                                    <template slot="title"> <i class="fas fa-cog fa-md"></i> ISO 27001 </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty mediun responsive outlined>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="60%" class="text-center" colspan="3" rowspan="2">
                                                                                <b>ISO 27001: 2013 Sistemas de gestión de sistemas de información </b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" rowspan="2">
                                                                                Dia / Fecha
                                                                            </b-td>
                                                                            <b-td width="20%" class="text-center" colspan="2">
                                                                                Tiempo
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" colspan="1" rowspan="2">
                                                                                Auditor
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>

                                                                            <b-td width="10%" class="text-center">
                                                                                SOY
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                PM
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-group label="Reunión de apertura realizada en:">
                                                                                    <b-form-checkbox-group plain v-model="datosPlanAuditoria.c27001[0].header[0].check" :options="datosPlanAuditoria.c27001[0].header[0].options" />
                                                                                </b-form-group>
                                                                                y seguido de una visita a la planta por parte del equipo de auditoría
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c27001[0].header[0].input1  = $event" :value="datosPlanAuditoria.c27001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c27001[0].header[0].input2  = $event" :value="datosPlanAuditoria.c27001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c27001[0].header[0].input3  = $event" :value="datosPlanAuditoria.c27001[0].header[0].input3" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c27001[0].header[0].input4  = $event" :value="datosPlanAuditoria.c27001[0].header[0].input4" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="data in datosPlanAuditoria.c27001[0].body" :key="data.index">
                                                                            <template v-if="data.total">
                                                                                <b-td v-if="data.hasLabel" :rowspan="data.size" colspan="7" class="valign-middle text-center">
                                                                                    <span>{{data.label}}</span>
                                                                                </b-td>
                                                                            </template>
                                                                            <template v-else-if="data.hasControl">
                                                                                <b-td colspan="3">
                                                                                    <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                            <template v-else>
                                                                                <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                                    <template v-if="data.categoriaVacia">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                                        <b-form-checkbox-group v-if="data.select" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                                        <b-form-group v-if="!data.sinauditado" label="Auditado:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </template>
                                                                                </b-td>
                                                                                <b-td :colspan="data.hasSubCheck ? '6' : '2'">
                                                                                    <div v-if="data.hasTexto">
                                                                                        <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheckGroup">
                                                                                        <b>Reunión de clausura en </b>
                                                                                        <b-form-checkbox-group plain v-model="data.checkGroup" :options="data.options">y asistir por el auditado y el equipo de auditoría</b-form-checkbox-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasCustom1">
                                                                                        <span>Análisis de datos y evaluación del desempeño - </span>
                                                                                        <ol type="a">
                                                                                            <li>Procesos y controles de SI
                                                                                                <b-form-checkbox v-model="data.check1" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Efectividad de las acciones tomadas para abordar la evaluación de riesgos de SI y el tratamiento de SI
                                                                                                <b-form-checkbox v-model="data.check2" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Desempeño y efectividad del SGSI
                                                                                                <b-form-checkbox v-model="data.check3" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Necesidad de mejoras al SGSI
                                                                                                <b-form-checkbox v-model="data.check4" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                        </ol>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheck">
                                                                                        <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                            {{data.labelSubCheck}}
                                                                                        </b-form-checkbox>
                                                                                    </div>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <b>Nota:</b>
                                                                                <ol>
                                                                                    <li>
                                                                                        <p>El auditor se referirá a las normas aplicables, siempre que sea necesario para revisar los requisitos.</p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Los auditores deben asegurarse de que el requisito del alcance de la certificación y el estándar contractual se verifique durante la auditoría en base a los registros de la planta de trabajo o de los registros anteriores de la planta de trabajo para lograr los objetivos de la auditoría independientemente de la conclusión de la auditoría. </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Programa de auditoría de vigilancia para SA-I y SA-II se detalla en el Informe IA-RC-Stage-II.
                                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.c27001[0].footer[0].check2" :options="datosPlanAuditoria.c27001[0].footer[0].options" />
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>El programa de auditoría de vigilancia se ha planificado en general teniendo en cuenta lasTamaño de la organización del cliente / Alcance / Complejidad del sistema de gestión, productos y procesos y nivel demostrado de eficacia del sistema de gestión y los resultados de las auditorías anteriores.</p>
                                                                                        <span>El Programa de Auditoría de Vigilancia puede revisarse considerando lo siguiente. (ISO 17021-I 2015 Cl 9.1.3)</span>
                                                                                        <ol type="a">
                                                                                            <li>
                                                                                                <p>Quejas recibidas por IQC sobre el cliente / Cambios en los requisitos de certificación; </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Cambios en los requisitos legales / Cambios en los requisitos de acreditación;</p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Datos de desempeño organizacional (por ejemplo, niveles de defectos, datos de indicadores clave de desempeño); </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Preocupaciones de las partes interesadas </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Auditoría de vigilancia no coordinada según las condiciones del certificado de cumplimiento </p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p>Se pueden considerar otros procesos para la auditoría con base en las observaciones de la auditoría.</p>
                                                                                            </li>
                                                                                        </ol>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Confidencialidad: Toda la información obtenida durante la ejecución de los servicios de Certificación se mantendrá como información confidencial y no se comunicará a ninguna persona o institución externa a menos que así lo requieran los sistemas legales locales y su aprobación.
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Responsabilidad: La responsabilidad de Integrated Quality Certification Pvt Ltd se limita al alcance de los servicios de certificación y términos y condiciones del acuerdo de certificación.
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>IQC solo es responsable de identificar el equipo de auditoría y delegar a los auditores competentes para evaluar los sistemas de gestión basados en los criterios de competencia según ISO 17021 y proporcionar recomendaciones para la certificación basadas en evidencia
                                                                                        </p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>Los siguientes procesos también deben ser auditados en cada función durante la etapa II de la auditoría. Información documentada / Retención de información documentada / Rol, responsabilidad y autoridad / Competencia / Infraestructura / Objetivos / Auditoría interna NC y acción correctiva / Mejora desde la última auditoría, según corresponda
                                                                                        </p>
                                                                                    </li>
                                                                                </ol>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                Confirmado que el Plan de Auditoría de Vigilancia se ha preparado según el Programa de Auditoría de Vigilancia, incluida cualquier revisión del programa de auditoría
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.c27001[0].footer[0].check" :options="datosPlanAuditoria.c27001[0].footer[0].options" />
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2" class="text-center">
                                                                                <b-form-group label="Elaborado por:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c27001[0].footer[0].elaboradoPor" @change="datosPlanAuditoria.c27001[0].footer[0].elaboradoPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="5" class="text-center">
                                                                                <b-form-group label="Revisado por Auditor:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c27001[0].footer[0].revisadorPor" @change="datosPlanAuditoria.c27001[0].footer[0].revisadorPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer4" v-if="disabledNorma4" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(avanzar)">
                                                <b-tab :title-item-class="(datosPlanAuditoria.idPlanAuditoria == '' && !isTemplate) ?  'disabledTab' : ''">
                                                    <template slot="title"> <i class="fas fa-cog fa-md"></i> ISO 37001 </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty mediun responsive outlined>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="60%" class="text-center" colspan="3" rowspan="1">
                                                                                <b>ISO 37001: 2016 Sistemas de gestión contra el soborno </b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" rowspan="2">
                                                                                Dia / Fecha
                                                                            </b-td>
                                                                            <b-td width="20%" class="text-center" colspan="2">
                                                                                Tiempo
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center" colspan="1" rowspan="2">
                                                                                Auditor
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="10%" class="text-center" colspan="3">
                                                                                <b>Procesos / actividades cubiertos en cada sitio temporal auditado</b>
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                SOY
                                                                            </b-td>
                                                                            <b-td width="10%" class="text-center">
                                                                                PM
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3" class="text-center">
                                                                                <b-form-group label="Reunión de apertura realizada en:">
                                                                                    <b-form-checkbox-group plain v-model="datosPlanAuditoria.c37001[0].header[0].check" :options="datosPlanAuditoria.c37001[0].header[0].options" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c37001[0].header[0].input1  = $event" :value="datosPlanAuditoria.c37001[0].header[0].input1" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c37001[0].header[0].input2  = $event" :value="datosPlanAuditoria.c37001[0].header[0].input2" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c37001[0].header[0].input3  = $event" :value="datosPlanAuditoria.c37001[0].header[0].input3" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-textarea rows="2" max-rows="6" @change="datosPlanAuditoria.c37001[0].header[0].input4  = $event" :value="datosPlanAuditoria.c37001[0].header[0].input4" placeholder="Escriba aqui..."></b-form-textarea>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-for="data in datosPlanAuditoria.c37001[0].body" :key="data.index">
                                                                            <template v-if="data.total">
                                                                                <b-td v-if="data.hasLabel" :rowspan="data.size" colspan="7" class="valign-middle text-center">
                                                                                    <span>{{data.label}}</span>
                                                                                </b-td>

                                                                            </template>
                                                                            <template v-else>
                                                                                <b-td v-if="data.hasCategoria" :rowspan="data.size" width="15%" class="valign-middle text-center">
                                                                                    <template v-if="data.categoriaVacia">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <b-form-textarea rows="2" class="mb-2" max-rows="6" @change="data.categoria  = $event" :value="data.categoria"></b-form-textarea>
                                                                                        <b-form-checkbox-group v-if="data.select" class="mb-2" v-model.lazy="data.select" :options="[{ value: 1, text: 'En el sitio' },{ value: 2, text: 'Auditoría remota' },]" small plain></b-form-checkbox-group>
                                                                                        <b-form-group v-if="!data.sinauditado" label="Auditado:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el auditado" :value="data.auditado" @change="data.auditado = $event"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </template>
                                                                                </b-td>
                                                                                <b-td :colspan="data.hasSubCheck ? '6' : '2'">
                                                                                    <div v-if="data.hasTexto">
                                                                                        <b-form-textarea rows="2" max-rows="6" @change="data.texto  = $event" :value="data.texto"></b-form-textarea>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheckGroup">
                                                                                        <b>Reunión de clausura en </b>
                                                                                        <b-form-checkbox-group plain v-model="data.checkGroup" :options="data.options">y asistir por el auditado y el equipo de auditoría</b-form-checkbox-group>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasSubCheck">
                                                                                        <b-form-checkbox v-model="data.subCheck" plain value="1" unchecked-value="0">
                                                                                            {{data.labelSubCheck}}
                                                                                        </b-form-checkbox>
                                                                                    </div>
                                                                                    <div v-else-if="data.hasCustom1">
                                                                                        <span>2.19. Análisis y evaluación de datos apropiados y información - </span>
                                                                                        <ol type="a">
                                                                                            <li>Implementación efectiva de sistemas de gestión QHSE</li>
                                                                                            <li>Número de incidentes, frecuencia de incidentes y tendencia</li>
                                                                                            <li>Porcentaje de acciones correctivas completadas a tiempo</li>
                                                                                            <li>Autoridades legales y reclamación de clientes</li>
                                                                                            <li>Cumplimiento de requisitos legales y de otro tipo.</li>
                                                                                            <li>Alcanzar los objetivos de QHSE</li>
                                                                                            <li>Efectividad de las acciones tomadas para abordar riesgos y oportunidades
                                                                                                <b-form-checkbox v-model="data.check1" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Desempeño de proveedores
                                                                                                <b-form-checkbox v-model="data.check2" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Necesidad de mejoras en el sistema de gestión de la calidad
                                                                                                <b-form-checkbox v-model="data.check3" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Conformidad de productos y servicios
                                                                                                <b-form-checkbox v-model="data.check4" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                            <li>Grado de satisfacción del cliente
                                                                                                <b-form-checkbox v-model="data.check5" plain value="1" unchecked-value="0" />
                                                                                            </li>
                                                                                        </ol>
                                                                                    </div>
                                                                                </b-td>

                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[0]" @change="data.itemsText[0] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[1]" @change="data.itemsText[1] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[2]" @change="data.itemsText[2] = $event" />
                                                                                </b-td>
                                                                                <b-td colspan="1" v-if="data.itemsText" :rowspan="data.sizeItems" class="valign-middle text-center">
                                                                                    <b-form-input :value="data.itemsText[3]" @change="data.itemsText[3] = $event" />
                                                                                </b-td>
                                                                            </template>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Programa de auditoría según informe de evaluación :</b>puede revisarse considerando cambios en los siguientes requisitos de certificación (ISO 17021-1: 2015 Cl 9.1.3). </p>
                                                                                <p><b>Los detalles de la información, como se muestra a continuación, serán proporcionados por IQC Operation para una posible revisión del programa de auditoría por parte del Líder del equipo de auditoría </b></p>
                                                                                <ul>
                                                                                    <li> Quejas recibidas por IQC sobre el cliente: <b-form-radio-group v-model="datosPlanAuditoria.c37001[0].footer[0].radio1" plain :options="[{ text: 'SI', value: 1 },{ text: 'Nulo', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de certificación: <b-form-radio-group v-model="datosPlanAuditoria.c37001[0].footer[0].radio2" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                    <li>Cambios en los requisitos de acreditación: <b-form-radio-group v-model="datosPlanAuditoria.c37001[0].footer[0].radio3" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]"></b-form-radio-group>
                                                                                    </li>
                                                                                </ul>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Confidencialidad:</b>Toda la información obtenida durante la ejecución de los servicios de Certificación se mantendrá como información confidencial y no se comunicará a ninguna persona o institución externa a menos que así lo requieran los sistemas legales locales y su aprobación. </p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7">
                                                                                <p><b>Responsabilidad:</b> La responsabilidad de Integrated Quality Certification Private Limited se limita al alcance de los servicios de certificación y los términos y condiciones del acuerdo de certificación.</p>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                <span>El Plan de Auditoría se ha elaborado considerando el programa de auditoría y la revisión aplicable.</span>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="7" class="text-center">
                                                                                Confirmado que el Plan de Auditoría de Vigilancia se ha preparado según el Programa de Auditoría de Vigilancia, incluida cualquier revisión del programa de auditoría
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.c37001[0].footer[0].check" :options="datosPlanAuditoria.c37001[0].footer[0].options" />
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2" class="text-center">
                                                                                <b-form-group label="Elaborado por:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c37001[0].footer[0].elaboradoPor" @change="datosPlanAuditoria.c37001[0].footer[0].elaboradoPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="5" class="text-center">
                                                                                <b-form-group label="Revisado por Auditor:">
                                                                                    <b-form-input :value="datosPlanAuditoria.c37001[0].footer[0].revisadorPor" @change="datosPlanAuditoria.c37001[0].footer[0].revisadorPor = $event" />
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button"> <i class="fas fa-arrow-left"></i> Anterior </b-button>
                                                            <b-button variant="success" type="submit"> <i class="fas fa-save"></i> Guardar </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer5" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(registrarPlanAuditoria)">
                                                <b-tab>
                                                    <template slot="title"> <i class="fas fa-server fa-md"></i> Datos generales </template>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Normas:" class="mb-2">
                                                                    <v-select @input="checkNormas" multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.normas" :options="listaNormas">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Dirección de contacto" v-slot="validationContext">
                                                                <b-form-group label="Dirección de contacto:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección de contacto" :value="datosPlanAuditoria.direccionContacto" @change="datosPlanAuditoria.direccionContacto = $event"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4">
                                                            <validation-provider name="sede" v-slot="validationContext">
                                                                <b-form-group label="Sede / Oficina central:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la sede" v-model.lazy="datosPlanAuditoria.sede"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4">
                                                            <validation-provider name="planta" v-slot="validationContext">
                                                                <b-form-group label="Planta:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la planta" v-model.lazy="datosPlanAuditoria.planta"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4">
                                                            <validation-provider name="contacto" v-slot="validationContext">
                                                                <b-form-group label="Contacto y designación del cliente:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model.lazy="datosPlanAuditoria.contacto"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Ubicación de la auditoría" v-slot="{errors}">
                                                                <b-form-group label="Ubicación de la auditoría:" class="mb-2">
                                                                    <v-select :reduce="listaUbicacionesAuditoria => listaUbicacionesAuditoria.idUbicacionAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.ubicacionAuditoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.ubicacionAuditoria" :options="listaUbicacionesAuditoria">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1">
                                                            <validation-provider name="Sitio temporal planificado" v-slot="{errors}">
                                                                <b-form-group label="Sitio temporal planificado:" class="mb-2">
                                                                    <v-select :reduce="listaSitioTemporalPlanificado => listaSitioTemporalPlanificado.idSitioTemporalPlanificado" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.sitioTemporalAuditado  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.sitioTemporalAuditado" :options="listaSitioTemporalPlanificado">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1">
                                                            <validation-provider name="Nro de Sitios" v-slot="validationContext">
                                                                <b-form-group label="Nro de Sitios:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nro de sitios" type="number" v-model.lazy="datosPlanAuditoria.nroSitios"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1">
                                                            <validation-provider name="Metodología de la auditoría" v-slot="{errors}">
                                                                <b-form-group label="Metodología de la auditoría:" class="mb-2">
                                                                    <v-select :reduce="listaMetodologiaAuditoria => listaMetodologiaAuditoria.idMetodologiaAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.metodologiaAuditoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.metodologiaAuditoria" :options="listaMetodologiaAuditoria">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Amable atención" v-slot="validationContext">
                                                                <b-form-group label="Amable atención:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la amable atención" v-model.lazy="datosPlanAuditoria.amableAtencion"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma3 || disabledNorma2 || disabledNorma4  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="móvil" v-slot="validationContext">
                                                                <b-form-group label="Móvil:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el móvil" v-model.lazy="datosPlanAuditoria.movil"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma3  || disabledNorma2 || disabledNorma4  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="correo electronico" v-slot="validationContext">
                                                                <b-form-group label="Correo electronico:" class="mb-2">
                                                                    <b-form-input type="email" :state="getValidationState(validationContext)" placeholder="Ingrese el correo electronico" v-model.lazy="datosPlanAuditoria.correoElectronico"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma2 || disabledNorma3 ">
                                                            <b-table-simple bordered show-empty mediun responsive outlined class="mt-2">
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="5" class="valign-middle">
                                                                            Ubicaciones del sitio del cliente
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.ubicacionesSitioCliente[0].check" value="1" unchecked-value="2" plain> Sitio único </b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-radio-group v-model="datosPlanAuditoria.ubicacionesSitioCliente[1].radio" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },]">Múltiples sitios según IAF MD 1 </b-form-radio-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Número de sitios múltiples: Oficina (s) regional (es): NA- Sucursal (es): </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.ubicacionesSitioCliente[2].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="60%" colspan="2" rowspan="1" class="valign-middle">
                                                                            <b-form-radio-group v-model="datosPlanAuditoria.ubicacionesSitioCliente[3].radio" plain :options="[{ text: 'Aplicable', value: 1 },{ text: 'No aplica', value: 2 },]">Sitios de proyectos temporales </b-form-radio-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Número de sitios activos del proyecto:
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.ubicacionesSitioCliente[4].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Número de sitios de proyectos temporales muestreados para ser auditados:
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.ubicacionesSitioCliente[5].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4">
                                                            <b-table-simple bordered show-empty mediun responsive outlined class="mt-2">
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="100%" colspan="3" class="valign-middle">
                                                                            PROCESOS PARA EL ALCANCE DE LA CERTIFICACIÓN
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Oficina central:
                                                                        </b-td>
                                                                        <b-td width="60%" colspan="2" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.procesoAlcanceCertificacion[0].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Planta:
                                                                        </b-td>
                                                                        <b-td width="60%" colspan="2" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.procesoAlcanceCertificacion[1].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Oficina Regional:
                                                                        </b-td>
                                                                        <b-td width="60%" colspan="2" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.procesoAlcanceCertificacion[2].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Sucursal:
                                                                        </b-td>
                                                                        <b-td width="60%" colspan="2" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.procesoAlcanceCertificacion[3].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="2" class="valign-middle">
                                                                            Sitio del proyecto temporal:
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Alcance de los servicios:
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.sitioProyectoTemporal[0].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            Procesos:
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="1" rowspan="1" class="valign-middle">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.sitioProyectoTemporal[1].text"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr v-if="disabledNorma2">
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle">
                                                                            <p><b>Enfoque de auditoria:</b> </p>
                                                                            <b-form-input type="text" v-model.lazy="datosPlanAuditoria.enfoqueAuditoria" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr v-if="disabledNorma2 ||  disabledNorma4">
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle">
                                                                            <span>Procesos no aplicables para el alcance de la certificación:</span>
                                                                            <b-form-input type="text" v-model.lazy="datosPlanAuditoria.procesosNoAplicables" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle">
                                                                            <p><b>Desempeño durante auditorías anteriores:</b> </p>
                                                                            <b-form-input type="text" v-model.lazy="datosPlanAuditoria.desempenoDurante" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle">
                                                                            <p><b>Aplicable solo para auditorías de SA:</b>Aplicable solo para auditorías de SA: ¿El certificado de cumplimiento está suspendido antes de la presente auditoría? </p>
                                                                            <b-form-radio-group v-model="datosPlanAuditoria.aplicableAuditoria" plain :options="[{ text: 'SI', value: 1 },{ text: 'NO', value: 2 },{ text: 'No aplica', value: 3 },]"> </b-form-radio-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4 ">
                                                            <b-table-simple bordered show-empty mediun responsive outlined class="mt-2">
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="25%" colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].header[0].check" value="1" unchecked-value="2" plain>Ubicaciones de auditoría </b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td width="13%" colspan="1" rowspan="2" class="valign-middle text-center">
                                                                            Oficina central
                                                                        </b-td>
                                                                        <b-td width="12%" colspan="1" rowspan="2" class="valign-middle text-center">
                                                                            Planta
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="2" class="valign-middle text-center">
                                                                            Sitio (s) del proyecto temporal
                                                                        </b-td>
                                                                        <b-td width="30%" colspan="3" rowspan="1" class="valign-middle text-center">
                                                                            Varias plantas / oficinas
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].header[1].check" value="1" unchecked-value="2" plain>Metodología de auditoría </b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            Planta (s)
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            Regional Oficina
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            Rama Oficinas
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle ">
                                                                            a. En el sitio
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].check1" value="1" unchecked-value="2" plain></b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].text1"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].check2" value="1" unchecked-value="2" plain></b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].text2"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].text3"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[0].text4"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle ">
                                                                            b. Fuera del sitio (revisión remota o de documentos)
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text1"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text2"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text3"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text4"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text5"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[1].text6"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle ">
                                                                            c. Remoto + en el sitio
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text1"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text2"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text3"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text4"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text5"></b-form-input>
                                                                        </b-td>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-input v-model.lazy="datosPlanAuditoria.desempenoAuditoria[0].body[2].text6"></b-form-input>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            La auditoría actual es una
                                                                        </b-td>
                                                                        <b-td colspan="5" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.auditoriaActualEsuna" :options="['Auditoría combinada', 'Auditoría conjunta', 'Auditoría integrada','Ninguna']" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="7" rowspan="1" class="valign-middle ">
                                                                            Requisitos del certificado de cumplimiento (COC)
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle ">
                                                                            Acreditación:
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.requisitosCoc[0].acreditacion[0].check1" value="1" unchecked-value="2" plain> JAS-ANZ </b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.requisitosCoc[0].acreditacion[0].check2" value="1" unchecked-value="2" plain> JNABCB </b-form-checkbox>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.requisitosCoc[0].acreditacion[0].check3" value="1" unchecked-value="2" plain> IAS </b-form-checkbox>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="2" class="valign-middle ">
                                                                            Área técnica:
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="Subdivisión:">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].subDivision"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="División:">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].division"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="Grupo:">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].grupo"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="Clase:">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].clase"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="1" rowspan="1" class="valign-middle ">
                                                                            El COC emitido incluirá
                                                                        </b-td>
                                                                        <b-td colspan="6" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.requisitosCoc[0].elcocincluira[0].select" :options="['Oficina central', 'Planta (s)','Oficina (s) regional (es)','Sucursal (es)', 'Sitio (s) temporal del proyecto']" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="7" rowspan="1" class="valign-middle ">
                                                                            <b>Realización de auditoría</b>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="Total de días-hombre de auditoría:">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.totalDiasHombre"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                        <b-td colspan="4" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-group label="Fecha de notificación de auditoría: ">
                                                                                <b-form-input v-model.lazy="datosPlanAuditoria.fechaNotificacionAuditoria" type="date"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" rowspan="1" class="valign-middle text-center">
                                                                            <span>Enlace para auditoría remota </span>
                                                                        </b-td>
                                                                        <b-td colspan="5" rowspan="1" class="valign-middle text-center">
                                                                            <template v-if="disabledNorma2 || disabledNorma4">
                                                                                <span>El enlace para la auditoría remota lo proporciona </span>
                                                                                <b-form-checkbox-group plain v-model="datosPlanAuditoria.enlaceProporciona" :options="['IQC','Cliente']" />
                                                                            </template>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="2" rowspan="3" class="valign-middle text-center">
                                                                            <span>Enlace para auditoría remota </span>
                                                                        </b-td>
                                                                        <b-td colspan="5" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.auditoriaOperaciones[0].check" value="1" unchecked-value="2" plain> Turno normal solo cuando el cliente está trabajando solo en un turno </b-form-checkbox>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="5" rowspan="1" class="valign-middle text-center">
                                                                            <template v-if="disabledNorma2 || disabledNorma4">
                                                                                <b-form-checkbox v-model.lazy="datosPlanAuditoria.auditoriaOperaciones[1].check" value="1" unchecked-value="2" plain>Horas de trabajo normales del turno diurno ya que los procesos son similares en cada turno </b-form-checkbox>
                                                                                <span>Registros de todas las operaciones de turnos revisados</span>
                                                                            </template>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="5" rowspan="1" class="valign-middle text-center">
                                                                            <template v-if="disabledNorma2  || disabledNorma4">
                                                                                <b-form-checkbox v-model.lazy="datosPlanAuditoria.auditoriaOperaciones[2].check" value="1" unchecked-value="2" plain>Horas de trabajo normales del turno diurno ya que los procesos son similares en cada turno </b-form-checkbox>
                                                                                <span>Registros de todas las operaciones de turnos revisados</span>
                                                                            </template>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle text-center">
                                                                            <span>Reunión de apertura:</span>
                                                                        </b-td>
                                                                        <b-td colspan="4" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.auditoriaOperaciones[3].select" :options="['Sede', 'Planta', 'Sitio del Proyecto']" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle text-center">
                                                                            <span>Reunión de cierre:</span>
                                                                        </b-td>
                                                                        <b-td colspan="4" rowspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox-group plain v-model="datosPlanAuditoria.auditoriaOperaciones[4].select" :options="['Sede', 'Planta', 'Sitio del Proyecto']" />
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma3 || disabledNorma2 || disabledNorma4 ">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="2" class="valign-middle text-center">
                                                                            <span>Criterios de auditoría: </span>
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <span> {{String(listaNormas.filter((x) => datosPlanAuditoria.normas.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' '))}} </span>
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <span>Documentos del sistema del cliente</span>
                                                                        </b-td>
                                                                        <b-td width="20%" colspan="1" rowspan="1" class="valign-middle text-center">
                                                                            <span>Requisitos legales / reglamentarios</span>
                                                                        </b-td>

                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td colspan="3" rowspan="1" class="valign-middle ">
                                                                            <p>Alcance de la certificación: :(En caso de cualquier producto cubierto en el alcance de la certificación pero no ejecutado por una causa, revise los registros de producción anteriores o los registros de revisión del contrato para confirmar la capacidad continua</p>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="mano de obra según auditoria anterior" v-slot="validationContext">
                                                                <b-form-group label="Mano de obra según auditoria anterior:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el mano de obra según auditoria anterior" v-model.lazy="datosPlanAuditoria.manoObraAuditoriaAnterior"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1  || disabledNorma3 || disabledNorma2 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="alcance de la certificación" v-slot="validationContext">
                                                                <b-form-group label="Alcance de la certificación:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el alcance de la certificación" v-model.lazy="datosPlanAuditoria.alcanceCertificacion"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Tipo de auditoría" v-slot="{errors}">
                                                                <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                                    <v-select :reduce="listaTipoAuditorias => listaTipoAuditorias.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.tipoAuditoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.tipoAuditoria" :options="listaTipoAuditorias">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1">
                                                            <validation-provider name="EA / NACE" v-slot="validationContext">
                                                                <b-form-group label="EA / NACE:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el EA / NACE" v-model.lazy="datosPlanAuditoria.eaNace"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Cuerpo de acreditación" v-slot="validationContext">
                                                                <b-form-group label="Cuerpo de acreditación:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Cuerpo de acreditación" v-model.lazy="datosPlanAuditoria.cuerpoAcreditacion"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="(disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Subdivisión" v-slot="validationContext">
                                                                <b-form-group label="Subdivisión:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la subdivision" v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].subDivision"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="(disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Clase" v-slot="validationContext">
                                                                <b-form-group label="Clase:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la clase" v-model.lazy="datosPlanAuditoria.requisitosCoc[0].areaTecnica[0].clase"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="ANZSIC" v-slot="validationContext">
                                                                <b-form-group label="ANZSIC:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ANZSIC" v-model.lazy="datosPlanAuditoria.anzsic"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Criterios de auditoría" v-slot="validationContext">
                                                                <b-form-group label="Criterios de auditoría:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los Criterios de auditoría" v-model.lazy="datosPlanAuditoria.criteriosAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Auditoría actual" v-slot="validationContext">
                                                                <b-form-group label="Auditoría actual:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la Auditoría actual" v-model.lazy="datosPlanAuditoria.auditoriaActual"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Auditoría anterior" v-slot="validationContext">
                                                                <b-form-group label="Auditoría anterior:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la Auditoría anterior" v-model.lazy="datosPlanAuditoria.auditoriaAnterior"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1  || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="¿El certificado de cumplimiento está suspendido antes de la presente auditoría (aplicable para auditorías de SA)? " v-slot="{errors}">
                                                                <b-form-group label="¿El certificado de cumplimiento está suspendido antes de la presente auditoría (aplicable para auditorías de SA)? :" class="mb-2">
                                                                    <v-select :reduce="listaSitioTemporalPlanificado => listaSitioTemporalPlanificado.idSitioTemporalPlanificado" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosPlanAuditoria.tipoAuditoria , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosPlanAuditoria.elCertificadoCumplimiento" :options="listaSitioTemporalPlanificado">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Total de días-hombre de auditoría" v-slot="validationContext">
                                                                <b-form-group label="Total de días-hombre de auditoría:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la total de días-hombre de auditoría" v-model.lazy="datosPlanAuditoria.totalDiasHombre"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Fecha de notificación de auditoría" v-slot="validationContext">
                                                                <b-form-group label="Fecha de notificación de auditoría:" class="mb-2">
                                                                    <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de notificación de auditoría" v-model.lazy="datosPlanAuditoria.fechaNotificacionAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="12" v-if="disabledNorma1 || (disabledNorma5 || disabledNorma6)">
                                                            <b-table-simple bordered show-empty mediun responsive outlined class="mt-2">
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="2" class="valign-middle">
                                                                            <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.notificacionParaAuditoriaRemota[0].text"></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td width="60%" colspan="2">
                                                                            <b-form-textarea v-if="disabledNorma1" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.notificacionParaAuditoriaRemota[1].text"></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="60%" colspan="2">
                                                                            <b-form-textarea v-if="disabledNorma1" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.notificacionParaAuditoriaRemota[2].text"></b-form-textarea>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="40%" colspan="1" rowspan="3" class="valign-middle">
                                                                            <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[0].text"></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td width="55%" colspan="1">
                                                                            <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[1].text"></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td width="5%" colspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[1].check" value="1" unchecked-value="2" plain> </b-form-checkbox>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="55%" colspan="1">
                                                                            <b-form-textarea v-if="disabledNorma1" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[2].text"></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td width="5%" colspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-if="disabledNorma1" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[2].check" value="1" unchecked-value="2" plain> </b-form-checkbox>
                                                                        </b-td>
                                                                    </b-tr>
                                                                    <b-tr>
                                                                        <b-td width="55%" colspan="1">
                                                                            <b-form-textarea v-if="disabledNorma1" rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[3].text"></b-form-textarea>
                                                                        </b-td>
                                                                        <b-td width="5%" colspan="1" class="valign-middle text-center">
                                                                            <b-form-checkbox v-if="disabledNorma1" v-model.lazy="datosPlanAuditoria.planificacionTiempoAuditoria[3].check" value="1" unchecked-value="2" plain> </b-form-checkbox>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>

                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Procesos de producción / servicios" v-slot="validationContext">
                                                                <b-form-group label="Procesos de producción / servicios:" class="mb-2">
                                                                    <b-form-textarea rows="2" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Procesos de producción / servicios" v-model.lazy="datosPlanAuditoria.procesosProduccionServicio[0]" />
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Una Planta" v-slot="validationContext">
                                                                <b-form-group label="Una Planta:" class="mb-2">
                                                                    <b-form-textarea rows="2" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Una Planta" v-model.lazy="datosPlanAuditoria.procesosProduccionServicio[1]" />
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Sitio si lo hay" v-slot="validationContext">
                                                                <b-form-group label="Sitio si lo hay:" class="mb-2">
                                                                    <b-form-textarea rows="2" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese Sitio si lo hay" v-model.lazy="datosPlanAuditoria.procesosProduccionServicio[2]" />
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Sitio temporal auditado" v-slot="validationContext">
                                                                <b-form-group label="Sitio temporal auditado:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Sitio temporal auditado" v-model.lazy="datosPlanAuditoria.sitioTemporalAuditado"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Procesos / actividades cubiertos en cada sitio temporal auditado " v-slot="validationContext">
                                                                <b-form-group label="Procesos / actividades cubiertos en cada sitio temporal auditado :" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los Procesos / actividades cubiertos en cada sitio temporal auditado " v-model.lazy="datosPlanAuditoria.procesosActividadesCubiertos"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Reunión de apertura de auditoría (fecha) " v-slot="validationContext">
                                                                <b-form-group label="Reunión de apertura de auditoría (fecha):" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" placeholder="Ingrese la Reunión de apertura de auditoría (fecha) " v-model.lazy="datosPlanAuditoria.reunionAperturaAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1|| (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Reunión de cierre de auditoría (fecha) " v-slot="validationContext">
                                                                <b-form-group label="Reunión de cierre de auditoría (fecha):" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" placeholder="Ingrese la Reunión de cierre de auditoría (fecha): " v-model.lazy="datosPlanAuditoria.reunionCierreAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 ||disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Auditor (es) TL: M / S" v-slot="validationContext">
                                                                <b-form-group label="Auditor (es) TL: M / S:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Auditor (es) TL: M / S" v-model.lazy="datosPlanAuditoria.auditorTlms"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="A2" v-slot="validationContext">
                                                                <b-form-group label="A2:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el A2" v-model.lazy="datosPlanAuditoria.auditor2"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="A3" v-slot="validationContext">
                                                                <b-form-group label="A3:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el A3" v-model.lazy="datosPlanAuditoria.auditor3"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Auditor en formación" v-slot="validationContext">
                                                                <b-form-group label="Auditor en formación:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Auditor en formación" v-model.lazy="datosPlanAuditoria.auditorFormacion"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="AO" v-slot="validationContext">
                                                                <b-form-group label="AO:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el AO" v-model.lazy="datosPlanAuditoria.ao"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="TE" v-slot="validationContext">
                                                                <b-form-group label="TE:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el TE" v-model.lazy="datosPlanAuditoria.te"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma1 || disabledNorma2 || disabledNorma3 || disabledNorma4 || (disabledNorma5 || disabledNorma6)">
                                                            <validation-provider name="Observador de auditoría" v-slot="validationContext">
                                                                <b-form-group label="Observador de auditoría:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Observador de auditoría" v-model.lazy="datosPlanAuditoria.observadorAuditoria"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="4" v-if="disabledNorma2">
                                                            <validation-provider name="Traductores / Intérpretes" v-slot="validationContext">
                                                                <b-form-group label="Traductores / Intérpretes:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Traductor / Intérprete" v-model.lazy="datosPlanAuditoria.traductoresInterpretes"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="4">
                                                            <validation-provider name="ubicación de auditoría" :rules="!isTemplate ? 'required' : ''" v-slot="{ errors }">
                                                                <b-form-group label="Ubicación de auditoría:" class="mb-2">
                                                                    <v-select multiple :reduce="(listaLugarAuditoria) => listaLugarAuditoria.idLugarAuditoria" label="nombre" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosPlanAuditoria.ubicacionAuditoria.length > 0,'style-invalid-select is-invalid': !!errors.length,}" v-model.lazy="datosPlanAuditoria.ubicacionAuditoria" :options="listaLugarAuditoria">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                   -->

                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button"> <i class="fas fa-arrow-left"></i> Volver </b-button>
                                                            <b-button variant="success" type="submit"> Guardar <i class="fas fa-save"></i> </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>
                                    </b-tabs>

                                    <!-- <b-row v-if="$route.params.id" class="text-center mt-2">
                                        <b-col md="12">
                                            <b-button class="mr-2" variant="dark" :to="{name: 'Documentos Stage 1'}" type="button">
                                                <i class="fas fa-arrow-left"></i> Volver
                                            </b-button>
                                        </b-col>
                                    </b-row> -->

                                    <!--  </b-form> -->
                                    <!-- </validation-observer> -->
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
    quillEditor
} from 'vue-quill-editor';

export default {
    components: {
        quillEditor,
    },
    data() {
        return {
            isTemplate: true,
            // editorOption: {
            //     placeholder: 'Escriba aqui...',
            //     // theme: 'bubble',
            //     modules: {
            //         toolbar: [
            //             ['bold', 'italic', 'underline', 'strike'],
            //             /* ['blockquote', 'code-block'], */
            //             [{
            //                     header: 1,
            //                 },
            //                 {
            //                     header: 2,
            //                 },
            //             ],
            //             [{
            //                     list: 'ordered',
            //                 },
            //                 {
            //                     list: 'bullet',
            //                 },
            //             ],
            //             [{
            //                     script: 'sub',
            //                 },
            //                 {
            //                     script: 'super',
            //                 },
            //             ],
            //             [{
            //                     indent: '-1',
            //                 },
            //                 {
            //                     indent: '+1',
            //                 },
            //             ],
            //             [{
            //                 direction: 'rtl',
            //             }, ],
            //             [{
            //                 size: ['small', false, 'large', 'huge'],
            //             }, ],
            //             [{
            //                 header: [1, 2, 3, 4, 5, 6, false],
            //             }, ],
            //             [{
            //                 font: [],
            //             }, ],
            //             [{
            //                     color: [],
            //                 },
            //                 {
            //                     background: [],
            //                 },
            //             ],
            //             [{
            //                 align: [],
            //             }, ],
            //             ['clean'],
            //             ['link', 'image', 'video'],
            //         ],
            //         syntax: {
            //             highlight: (text) => hljs.highlightAuto(text).value,
            //         },
            //     },
            // },

            listaUbicacionesAuditoria: [{
                idUbicacionAuditoria: 1,
                descripcion: 'Oficina Registrada'
            }, {
                idUbicacionAuditoria: 2,
                descripcion: 'Planta'
            }, {
                idUbicacionAuditoria: 3,
                descripcion: 'Oficina'
            }, {
                idUbicacionAuditoria: 4,
                descripcion: 'Sucursales'
            }, ],

            listaSitioTemporalPlanificado: [{
                idSitioTemporalPlanificado: 1,
                descripcion: 'SI'
            }, {
                idSitioTemporalPlanificado: 2,
                descripcion: 'NO'
            }, {
                idSitioTemporalPlanificado: 3,
                descripcion: 'NO PLANIFICADO'
            }, ],
            listaMetodologiaAuditoria: [{
                idMetodologiaAuditoria: 1,
                descripcion: 'En el sitio'
            }, {
                idMetodologiaAuditoria: 2,
                descripcion: 'Remoto'
            }],
            datosPlanAuditoria: {
                idPlanAuditoria: '',
                idCliente: null,
                idAuditoria: null,
                normas: [],
                sede: '', //45k
                planta: '', //45k
                contacto: '', //45k
                ubicacionesSitioCliente: [{
                        check: 2,
                    }, {
                        radio: 2,
                    },
                    {
                        text: '',
                    }, {
                        radio: 2,
                    }, {
                        text: '',
                    }, {
                        text: ''
                    }
                ], //45k,
                procesoAlcanceCertificacion: [{
                        text: ''
                    },
                    {
                        text: ''
                    }, {
                        text: ''
                    }, {
                        text: ''
                    },
                ], //45k,

                sitioProyectoTemporal: [{
                    text: ''
                }, {
                    text: ''
                }], //45k,

                enfoqueAuditoria: 'Enfoque de auditoría estándar según IAF MD 11', // 14 
                procesosNoAplicables: '', // 14 
                desempenoDurante: 'No hay problemas sin resolver y el desempeño anterior de OHSAS / OHSMS es aceptable. Consulte el presente Informe de evaluación de auditoría para obtener más detalles', // 14 

                desempenoAuditoria: [{
                    header: [{
                        check: 2
                    }, {
                        check: 2
                    }],
                    body: [{
                        check1: 2,
                        text1: '',
                        check2: 2,
                        text2: '',
                        text3: '',
                        text4: '',
                    }, {
                        text1: '',
                        text2: '',
                        text3: '',
                        text4: '',
                        text5: '',
                        text6: '',
                    }, {
                        text1: '',
                        text2: '',
                        text3: '',
                        text4: '',
                        text5: '',
                        text6: '',
                    }]
                }], //45k
                auditoriaActualEsuna: [], //45k
                requisitosCoc: [{
                    acreditacion: [{
                        check1: 2,
                        check2: 2,
                        check3: 2,
                    }],
                    areaTecnica: [{
                        subDivision: '',
                        division: '',
                        grupo: '',
                        clase: ''
                    }],
                    elcocincluira: [{
                        select: [],
                    }]
                }], //45k
                enlaceProporciona: [], //45k
                auditoriaOperaciones: [{
                    check: 2,
                }, {
                    check: 2,
                }, {
                    check: 2,
                }, {
                    select: [],
                }, {
                    select: [],
                }, ],
                traductoresInterpretes: '', //45k
                direccionContacto: '', // 9k 
                ubicacionAuditoria: null, // 9k 
                sitioTemporalAuditado: null, // 9k 
                nroSitios: 0, // 9k 
                metodologiaAuditoria: null, // 9k 
                amableAtencion: '', // 9k 
                movil: '', // // 9k 
                manoObraAuditoriaAnterior: '', // 9k 
                correoElectronico: '', // 9k 
                alcanceCertificacion: '', // 9k 
                tipoAuditoria: null, // 9k 
                eaNace: '', // 9k 
                anzsic: '', // 9k 
                cuerpoAcreditancion: '', // 9k 
                criteriosAuditoria: '', // 9k 
                auditoriaActual: '', // 9k 
                auditoriaAnterior: '', // 9k 
                elCertificadoCumplimiento: null, // 9k 
                totalDiasHombre: 0, // 9k //45
                fechaNotificacionAuditoria: null, // 9k //45
                notificacionParaAuditoriaRemota: [{ // 9k 
                    text: 'Notificación para infraestructura y auditoría remota'
                }, { // 9k 
                    text: 'IQC proporcionará el enlace necesario un día antes de la reunión de apertura. '
                }, {
                    text: 'Sala de reuniones con herramientas de comunicación por video según lo solicitado para videoconferencia Zoom o WebEx y un teléfono celular con herramientas de comunicación solicitadas para la visita a la planta y auditoría de las instalaciones de la planta'
                }, ], // 9k 
                planificacionTiempoAuditoria: [{
                    text: 'Planificación del tiempo de auditoría'
                }, {
                    text: 'Planificado durante el horario laboral normal ya que el cliente trabaja solo en turno de día ',
                    check: 2
                }, {
                    text: 'Planificado durante el horario laboral normal, ya que los procesos y las operaciones son similares en cada turno. ',
                    check: 2
                }, {
                    text: 'Planificado para realizarse durante el turno de día y parte del turno siguiente considerando diferentes procesos / operaciones en cada turno ',
                    check: 2
                }, ], // 9k 
                procesosProduccionServicio: ['', '', ''], // 9k 

                procesosActividadesCubiertos: '', // 9k 
                reunionAperturaAuditoria: '', // 9k 
                reunionCierreAuditoria: '', // 9k 
                auditorTlms: '', // 9k 
                auditor2: '', // 9k 
                auditor3: '', // 9k 
                auditorFormacion: '', // 9k 
                ao: '', // 9k 
                te: '', // 9k 
                observadorAuditoria: '',

                c9001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                        input3: '',
                        input4: '',
                    }, {
                        input1: 'Sitio temporal auditado',
                        input2: '',
                    }, {
                        input1: 'Procesos / actividades cubiertos en cada sitio temporal auditado',
                        input2: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: 'Alta Dirección',
                            auditado: '',
                            size: "13",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            itemsText: ['', '', '', ''],
                            sizeItems: 4
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación QMS',
                        },
                        {
                            hasTexto: true,
                            texto: 'Requisitos legales aplicables y actualización de los requisitos',
                        },
                        {
                            hasTexto: true,
                            texto: 'Alcance del SGC (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Política y comunicación del SGC (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos del SGC y consecución de objetivos',
                        }, {
                            hasTexto: true,
                            texto: 'Efectividad del SGC',
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de reclamaciones legales y de clientes (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 6
                        }, {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna ',
                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Representante de la dirección: función responsable del SGC implementación y coordinación',
                            auditado: '',
                            size: "16",
                            select: [],
                            hasTexto: true,
                            texto: 'Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 8
                        },
                        {
                            texto1: '',
                            label1: 'Tiempo completo',
                            placeholder1: 'el tiempo completo',
                            hasSubTexto: true,
                            texto2: '',
                            label2: 'Tiempo parcial',
                            placeholder2: 'el tiempo parcial',
                            detalles: '',
                        }, {
                            texto1: '',
                            label1: 'Estacional',
                            placeholder1: 'estacional',
                            hasSubTexto: true,
                            texto2: '',
                            label2: 'Mano de obra total',
                            placeholder2: 'la mano de obra total',
                            detalles: '',
                        }, {
                            hasTexto: true,
                            texto: 'Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones / OFI (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Contexto de la organización y partes interesadas',
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de la comunicación',
                        }, {
                            hasTexto: true,
                            texto: 'Procesos de SGC e información documentada',
                        }, {
                            hasTexto: true,
                            texto: 'Planificación - Riesgos y oportunidades - Eficacia de acciones para riesgos y oportunidades - Objetivos de calidad - Planificación para lograr objetivos',
                        }, {
                            hasTexto: true,
                            texto: 'Planificación de cambios en QMS',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        }, {
                            hasTexto: true,
                            texto: 'Análisis de datos y evaluación del desempeño -  a.Análisis y evaluación de productos y servicios b.Conformidad - satisfacción del cliente c.Desempeño y efectividad del SGC d.Efectividad de acciones para riesgos y oportunidades - desempeño de proveedores externos e.Necesidad de mejorar el SGC ',
                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Criterios de auditoría - Alcance de la auditoría - Informar el resultado de la auditoría a la gerencia - Corrección y acción correctiva - Retención de información documentada (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 4
                        }, {
                            hasTexto: true,
                            texto: 'Frecuencia de revisión por la dirección - Entrada de puntos de la agenda de MRM - Salida de MRM y Eficacia del sistema de gestión con respecto al logro de los objetivos y los resultados previstos. (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de no conformidades producto-proceso y sistema, Quejas de clientes - Acción correctiva (obligatoria para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Objetivos funcionales y consecución de objetivos, Corrección y acción correctiva de las observaciones de Auditoría, retención de información documentada',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua considerando resultado de auditoría interna-MRM- resultados de evaluación (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 6
                        }, {
                            hasTexto: true,
                            texto: 'Uso de marcas de certificación y acreditación (obligatorio para cada auditoría)',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Recursos humanos / formación',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - conciencia',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Mantenimiento',
                            size: "2",
                            select: [],
                            auditado: '',
                            hasTexto: true,
                            texto: 'Mantenimiento de Infraestructura - Control de calibración de activos operativos - instrumentos, si aplica - retención de información documentada',
                            detalles: '',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            total: true,
                            hasLabel: true,
                            label: 'Almuerzo de trabajo  13.30 a 14.00 horas o según los requisitos locales',
                            size: "1",
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Comercial y contratos',
                            size: "5",
                            sizeItems: 5,
                            auditado: '',
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de la consulta y el contrato del cliente - Identificación del número de contrato / trabajo - cambios en el contrato - retención de información documentada ',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasTexto: true,
                            texto: 'Actividades posteriores a la entrega (Garantía - Garantía - Devolución del producto - Reparación - Quejas del cliente)',
                        },
                        {
                            hasTexto: true,
                            texto: 'Medición y evaluación de la satisfacción del cliente.',
                        },
                        {
                            hasTexto: true,
                            texto: 'Revisión de la propiedad suministrada por el cliente (materiales-planos-especificaciones)',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Diseño',
                            size: "2",
                            sizeItems: 2,
                            auditado: '',
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de los datos e insumos del diseño del cliente - Diseño del producto / servicio - calibración y validación del software de diseño, si corresponde - Control de los dibujos de ingeniería, incluidas las revisiones - propiedad proporcionada por el cliente (dibujos y especificaciones) - aprobación de la salida del diseño - revisión del diseño - validación del diseño - diseño verificación - aprobación del cliente, si se requiere - liberación de dibujos para producción - retención de información documentada',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Ingenieria',
                            size: "2",
                            sizeItems: 2,
                            select: [],
                            auditado: '',
                            hasTexto: true,
                            texto: 'Revisión de los dibujos y datos de entrada del cliente - Control de los dibujos de ingeniería, incluidas las revisiones - Propiedad proporcionada por el cliente (Dibujos y especificaciones) - Revisión de los dibujos - Retención de la información documentada',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Obtención',
                            size: "2",
                            sizeItems: 2,
                            select: [],
                            auditado: '',
                            hasTexto: true,
                            texto: 'Selección - Evaluación - reevaluación de proveedores externos - Mantener la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales, corrección y acción correctiva para las observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Historias administración',
                            size: "2",
                            sizeItems: 2,
                            auditado: '',
                            select: [],
                            hasTexto: true,
                            texto: 'Entorno para las tiendas - Control del almacenamiento y recepción / emisión de material - control de la vida útil de los artículos, si corresponde - Control de los materiales suministrados por el cliente - Despacho de productos - retención de información documentada',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoriaVacia: true,
                            size: "1",
                            sizeItems: 1,
                            auditado: '',
                            hasTexto: true,
                            texto: 'Reunión informativa del día 1',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Provisión de producción / servicio',
                            size: "5",
                            auditado: '',
                            hasSubCheck: true,
                            select: [],
                            subCheck: null,
                            labelSubCheck: 'a.	Auditado durante el horario laboral normal ya que el cliente trabaja solo en turno de día',
                        },
                        {
                            hasSubCheck: true,
                            subCheck: true,
                            labelSubCheck: 'b.	Auditado durante el horario laboral normal ya que los procesos son similares en cada turno ',
                        },
                        {
                            hasSubCheck: true,
                            subCheck: true,
                            labelSubCheck: 'c.	Auditoría realizada durante el turno normal y parte del turno considerando diferentes procesos en cada turno',
                        },
                        {
                            hasTexto: true,
                            texto: 'Planificación y control de la producción - entorno para la operación del proceso - Control operativo para las operaciones de producción / servicio - Validación del proceso - Almacenamiento del producto en proceso - Liberación del producto - Control de cambios',
                            itemsText: ['', '', '', ''],
                            sizeItems: 6,
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Sitio del proyecto',
                            size: "1",
                            auditado: '',
                            select: [],
                            hasTexto: true,
                            texto: 'Alcance de los servicios en el sitio - Plan del proyecto - plan de movilización - control de los documentos de ingeniería, incluidos los planos - competencia del personal - Almacenamiento de materias primas - Inspección de materiales entrantes - control de la propiedad suministrada por el cliente - Equipo de protección personal - Instalación - puesta en servicio - inspección y pruebas - interfaz con clientes y representantes de clientes - retención de información documentada ',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Seguro de Calidad / Control de Calidad',
                            size: "3",
                            select: [],
                            auditado: '',
                            hasTexto: true,
                            texto: 'Revisar el plan de aseguramiento de la calidad / plan de inspección - revisar la interfaz con el cliente o el representante del cliente - inspección entrante - inspección en proceso - inspección del producto final - liberación del producto - retención de información documentada Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos - retención de información documentada',
                        },
                        {
                            hasTexto: true,
                            texto: 'Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos - retención de información documentada',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales - Corrección y acción correctiva para las observaciones de auditoría - retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Líder del equipo de auditoría de IQC',
                            size: "2",
                            sinauditado: true,
                            itemsText: ['', '', '', ''],
                            sizeItems: 2,
                            hasTexto: true,
                            texto: 'Alcance de los servicios en el sitio - Plan del proyecto - plan de movilización - control de los documentos de ingeniería, incluidos los planos - competencia del personal - Almacenamiento de materias primas - Inspección de materiales entrantes - control de la propiedad suministrada por el cliente - Equipo de protección personal - Instalación - puesta en servicio - inspección y pruebas - interfaz con clientes y representantes de clientes - retención de información documentada ',
                        }, {
                            hasSubCheckGroup: true,
                            checkGroup: [],
                            options: [{
                                    text: 'Oficina registrada',
                                    value: '1'
                                },
                                {
                                    text: 'Planta',
                                    value: '2'
                                },
                                {
                                    text: 'Sitio',
                                    value: '3'
                                },

                            ],
                        },
                    ],
                    footer: [{
                        elaboradoPor: '',
                        revisadoPor: '',
                        check: [],
                        check2: [],
                        options: [{
                                text: 'SI',
                                value: '1'
                            },
                            {
                                text: 'NO',
                                value: '2'
                            },
                            {
                                text: 'N/A',
                                value: '3'
                            },

                        ],
                    }]
                }, ],
                c45001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                        input3: '',
                        input4: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: '1.0 Administración',
                            size: "8",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            itemsText: ['', '', '', ''],
                            sizeItems: 8
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación OHSMS',
                        },
                        {
                            hasTexto: true,
                            texto: 'Efectividad de OHSMS',
                        },
                        {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la gestión y la auditoría internas Revisar ',

                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                        },
                        {
                            hasCategoria: true,
                            categoria: '2.0. Representante de HSE responsable de OHSMS implementación y coordinación',
                            size: "20",
                            select: [],
                            hasTexto: true,
                            texto: '2.1.	Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 12
                        },
                        {
                            hasTexto: true,
                            texto: '2.2.	Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones u OFI (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.3.	Alcance del OHSMS (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.4.	Política de OHSMS y comunicación (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.5.	Gestión de reclamaciones legales y de clientes (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna (obligatoria para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.6.	Revisión por la dirección (obligatoria para cada auditoría) ',
                        }, {
                            hasTexto: true,
                            texto: '2.7.	Identificación de peligros y evaluación de riesgos y Oportunidades para actividades rutinarias y no rutinarias (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.8.	Gestión de cambios relacionados con: ubicaciones de trabajo y entorno - organización del trabajo - condiciones de trabajo - equipo - fuerza de trabajo - cambios en los requisitos legales y otros requisitos - conocimiento o información sobre peligros y riesgos de SST - desarrollos en el conocimiento y la tecnología (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.9.	Gestión de no conformidades - Notificación de incidencias e investigación - Quejas de los clientes - acciones correctivas (obligatorias para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.10.	Programa de mejora continua (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.11.	Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría)',
                        },
                        {
                            hasTexto: true,
                            texto: '2.12.	Consulta y participación de trabajadores',
                            sizeItems: 8,
                            itemsText: ['', '', '', ''],
                        }, {
                            hasTexto: true,
                            texto: '2.13.	Contexto de la organización',
                        }, {
                            hasTexto: true,
                            texto: '2.14.	Planificación - Riesgos y oportunidades-',
                        }, {
                            hasTexto: true,
                            texto: '2.15.	Requisitos legales y de otro tipo, cumplimiento obligación y evaluación ',
                        }, {
                            hasTexto: true,
                            texto: '2.16.	Objetivos de OHSMS y su consecución (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: `2.17.	Evaluación del desempeño de OHSMS 
2.17.1.	Criterios para la evaluación del desempeño en planta 
2.17.2.	Criterios para el sitio del proyecto de evaluación del desempeño, si 
aplicable
2.17.3.	Cumplimiento de requisitos legales y de otro tipo.
2.17.4.	Alcanzar los objetivos de OHSMS`,
                        }, {
                            hasTexto: true,
                            texto: '2.18.	Comunicación OHSMS relevantes tanto Incluyendo internamente a los trabajadores y las partes interesadas externas - visitantes-contratistas en la gestión del cambio - acciones correctivas - informes de incidentes y resultado de la investigación - Política de OHSMS, según corresponda',
                        }, {
                            hasTexto: true,
                            texto: '2.19.	Información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: '3.0	Recursos humanos / Capacitación',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa - Roles, Responsabilidad, Autoridad - Gestión de competencias - Plan de formación y cumplimiento - Sensibilización - Vigilancia de la salud ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: '4.0 Mantenimiento',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: 'Mantenimiento de Infraestructura - calibración o verificación de activos para medir OHSMS –- retención de información documentada Inspección de activos, incluidos extintores de incendios, detectores de incendios y humo, alarma de incendios, prevención de posibles fugas',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: '5.0 Adquisiciones',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Proceso de adquisición de productos y servicios para cumplir con OHSMS',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'Proceso de adquisición - selección de contratistas considerando los criterios de SST - identificar peligros, evaluar y controlar los riesgos de SST que surgen de: actividades y operaciones e impacto en la organización del cliente - impacto en los trabajadores de los contratistas - impacto en otras partes interesadas en el lugar de trabajo',
                        },
                        {
                            hasTexto: true,
                            texto: 'Control de funciones y procesos subcontratados con cumplimiento de requisitos legales y otros',
                        },

                        {
                            hasCategoria: true,
                            categoria: '6.0	Tiendas',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: 'Preservación relacionada con OHSMS y retención de certificados',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            total: true,
                            hasLabel: true,
                            label: 'Almuerzo de trabajo  13.30 a 14.00 horas o según los requisitos locales',
                            size: "1",
                        },
                        {
                            hasCategoria: true,
                            categoria: '7.0 Planificación y control operacional (obligatorio para cada auditoría)',
                            size: "4",
                            hasSubCheck: true,
                            select: [],
                            subCheck: null,
                            labelSubCheck: 'a.	Auditoría durante el horario laboral normal ya que el cliente trabaja solo en turno de día',
                        },

                        {
                            hasTexto: true,
                            texto: `Control de los procesos para el cumplimiento de OHSMS y verificación de acciones determinadas en evaluación de peligros y riesgos, incluida criterios y control de los procesos 
-	adaptar el trabajo a los trabajadores.
-	Coordinar con otras organizaciones en caso de lugares de trabajo de múltiples empleadores para partes relevantes de OHSMS 
`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        },
                        {
                            hasTexto: true,
                            texto: `Verificar el control de peligros para procesos específicos. considerando la evaluación de peligros y riesgos, según corresponda, para las siguientes situaciones en la planta y el sitio del proyecto:
Ruido - Vibración - Radiación - Enfermedad mental - Violencia en el trabajo - Abuso de sustancias en el trabajo - Manipulación manual - Equipo de manejo de carga - Sustancias peligrosas - Límites de exposición ocupacional - Trabajo en espacios confinados de altura - Trabajo en solitario - Resbalones y tropiezos - Movimiento de personas y vehículos en los lugares de trabajo - conducción relacionada con el trabajo - Incendio - Derrames de productos químicos - Mordeduras de serpientes - Herramientas manuales-Maquinaria - Electricidad - Desastres naturales 
`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 2,
                        },
                        {
                            hasTexto: true,
                            texto: `Identificar situaciones de emergencia: realizar simulacros planificados para todas las situaciones de emergencia `,
                        },
                        {
                            hasCategoria: true,
                            categoria: '8.0 Sitio del proyecto (obligatorio para cada auditoría, si corresponde)',
                            size: "1",
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            select: [],
                            hasTexto: true,
                            texto: 'Alcance de los servicios - Criterios y control de operaciones - competencia -  Criterios y control para la operación del proceso considerando el peligro de cada proceso (s) - conocimiento de los requisitos de OHSMS por parte de los trabajadores - Criterios para evaluar el desempeño de OHSMS en el sitio y el cumplimiento de los arreglos planificados y los requisitos legales aplicables - control de una posible situación de emergencia, incluidos simulacros de simulación - inspección de activos como extintores de incendios, detectores de incendios y humo, equipos de elevación y accesorios y otros',
                        },

                        {
                            hasCategoria: true,
                            categoria: 'Líder del equipo de auditoría de IQC',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            hasTexto: true,
                            texto: 'Los auditores se reúnen y auditan la preparación del informe por parte del equipo de auditoría.',
                        }, {
                            hasSubCheckGroup: true,
                            checkGroup: [],
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            options: [{
                                    text: 'Oficina registrada',
                                    value: '1'
                                },
                                {
                                    text: 'Planta',
                                    value: '2'
                                },
                                {
                                    text: 'Sitio',
                                    value: '3'
                                },

                            ],
                        },
                    ],
                    footer: [{
                        radio1: 2,
                        radio2: 2,
                        radio3: 2,
                        elaboradoPor: '',
                        revisadoPor: '',
                        check: [],
                        options: [{
                                text: 'SI',
                                value: '1'
                            },
                            {
                                text: 'NO',
                                value: '2'
                            },
                            {
                                text: 'N/A',
                                value: '3'
                            },

                        ],
                    }]
                }, ],
                c14001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                        input3: '',
                        input4: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: '1.0 Administración',
                            auditado: '',
                            size: "8",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            itemsText: ['', '', '', ''],
                            sizeItems: 15
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación HSE',
                        },
                        {
                            hasTexto: true,
                            texto: 'Efectividad de HSE',
                        },
                        {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la gestión y la auditoría internas Revisar ',

                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                        },
                        {
                            hasCategoria: true,
                            categoria: '2.0.	QHSE Representante: responsable de los sistemas de gestión de QHSE',
                            auditado: '',
                            size: "21",
                            select: [],
                            hasTexto: true,
                            texto: '2.1.	Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría)',
                        },
                        {
                            hasTexto: true,
                            texto: '2.2.	Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones u OFI (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.3.	Alcance de QHSE MS (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.4.	Política de HSE y comunicación (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.5.	Objetivos de HSE y consecución de objetivos (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.6.	Procesos de HSE e información documentada ',
                        }, {
                            hasTexto: true,
                            texto: '2.7.	Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Auditoría Criterios - Alcance de la auditoría - Informe del resultado de la auditoría a la dirección - Corrección y acción correctiva (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.8.	Revisión de gestión - Frecuencia de revisión por la dirección - Entrada de punto de agenda MRM - Salida MRM y Efectividad de la sistema de gestión con respecto al logro de los objetivos y los resultados previstos (obligatorio para cada auditoría) ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: '2.9.	Identificación de peligros y evaluación de riesgos y Oportunidades para actividades rutinarias y no rutinarias (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        }, {
                            hasTexto: true,
                            texto: '2.10.	Evaluación de aspectos e impacto - Perspectiva del ciclo de vida - Medioambiental significativo aspectos (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.11.	Gestión de cambios relacionada con: lugar de trabajo Ubicaciones y entorno - organización del trabajo - condiciones de trabajo - equipo - fuerza de trabajo - cambios en los requisitos legales y otros requisitos - conocimiento o información sobre peligros y riesgos de SST - desarrollos en el conocimiento y la tecnología (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 11
                        },
                        {
                            hasTexto: true,
                            texto: '2.12.	Gestión de no conformidades - Notificación de incidencias e investigación - Quejas de clientes - Quejas de autoridades legales - acciones correctivas (obligatorias para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.13.	Programa de mejora continua (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.14.	Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: '2.15.	Consulta y participación de trabajadores - No gerencial trabajadores',
                        }, {
                            hasTexto: true,
                            texto: '2.16.	Contexto de la organización: interno y externo cuestiones: expectativas de las partes interesadas',
                        }, {
                            hasTexto: true,
                            texto: `2.17.	Riesgos y oportunidades considerando el contexto, partes interesadas y procesos QHSE - gestión del cambio`,
                        }, {
                            hasTexto: true,
                            texto: '2.18.	Determinación de requisitos legales actualizados - frecuencia de evaluación - Evaluación de cumplimiento ',
                        }, {
                            hasCustom1: true,
                            check1: 0,
                            check2: 0,
                            check3: 0,
                            check4: 0,
                            check5: 0,
                        },
                        {
                            hasTexto: true,
                            texto: '2.20.	Comunicación interna y externa QHSE relevante incluidos los trabajadores y las partes interesadas externas - visitantes-contratistas en la gestión del cambio - acciones correctivas - informes de incidentes y resultado de la investigación - Política de OHSMS, según corresponda',
                        },
                        {
                            hasTexto: true,
                            texto: '2.21.	Información documentada creación y actualización de documentos - distribución - recuperación - almacenamiento - control de cambios - retención - disposición - control de información documentada de origen externo',
                        },
                        {
                            hasCategoria: true,
                            categoria: '3.0	Recursos humanos / Capacitación',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: `a.	Estructura de la organización - Funciones, responsabilidad, autoridad para los sistemas de gestión de QHSE - 
b.	Gestión de competencias - plan de formación y cumplimiento - sensibilización
c.	Vigilancia de la salud: retención de información documentada`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasTexto: true,
                            texto: 'Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: '4.0 Mantenimiento',
                            auditado: '',
                            size: "4",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Mantenimiento de la infraestructura: retención de documentos información ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 4
                        },
                        {
                            hasTexto: true,
                            texto: 'b.	Control de monitoreo y medición calibrados o verificados Equipo para medir el desempeño de QHSE - Revisión de certificados de calibración de equipos y accesorios de elevación, instrumentos de medición y trazabilidad a estándares nacionales. retención de información documentada',
                        },
                        {
                            hasTexto: true,
                            texto: 'c.	Inspección de activos, incluidos extintores de incendios, incendios y detectores de humo, alarma de incendio, prevención de posibles fugas',
                        },
                        {
                            hasTexto: true,
                            texto: 'd.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            hasCategoria: true,
                            categoria: '5.0.	Operaciones - Comercial - Marketing ',
                            auditado: '',
                            size: "5",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Revisión de consulta y contrato del cliente - Contrato / identificación del trabajo - cambios en el contrato - retención de información documentada ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 5
                        },
                        {
                            hasTexto: true,
                            texto: 'b.	Actividades posteriores a la entrega (Garantía - Garantía - Devolución de producto - Reparación - Quejas de clientes)',
                        },
                        {
                            hasTexto: true,
                            texto: 'c.	Medición y evaluación de la satisfacción del cliente.',
                        },
                        {
                            hasTexto: true,
                            texto: 'd.	Revisión de la propiedad suministrada por el cliente (materiales-dibujos-especificaciones)',
                        },
                        {
                            hasTexto: true,
                            texto: 'e.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            hasCategoria: true,
                            categoria: '6.0.	Operaciones - Diseño y desarrollo',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Planificación del diseño - Revisión de los datos e insumos del diseño del cliente - Diseño del producto / servicio - revisión del diseño - validación del diseño - Calibración y validación del software de diseño, si corresponde - Control de los planos de ingeniería, incluidas las revisiones ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'b.	Propiedad suministrada por el cliente (dibujos y especificaciones) - aprobación del resultado del diseño - revisión del diseño - validación del diseño - verificación del diseño - aprobación del cliente, si es necesario',
                        },
                        {
                            hasTexto: true,
                            texto: 'c.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            total: true,
                            hasLabel: true,
                            label: 'Almuerzo de trabajo  13.30 a 14.00 horas o según los requisitos locales',
                            size: "1",
                        },
                        {
                            hasCategoria: true,
                            categoria: '7.0.	Operaciones - Ingenieria',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Revisión de dibujos y datos de entrada del cliente - Control de dibujos de ingeniería que incluyen revisiones - propiedad proporcionada por el cliente (dibujos y especificaciones) - revisión de dibujos - retención de información documentada',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'b.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            hasCategoria: true,
                            categoria: '8.0.	Operaciones - Obtención ',
                            auditado: '',
                            size: "5",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Selección - Evaluación - reevaluación de externos proveedores de productos y servicios teniendo en cuenta los criterios de QHSE –– requisito medioambiental para la adquisición de productos ',
                        }, {
                            hasTexto: true,
                            texto: 'b.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'c.	Control de funciones y procesos subcontratados con Cumplimiento de los requisitos legales y de otro tipo, incluido el tipo de control y el alcance del control.',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        }, {
                            hasTexto: true,
                            texto: 'd.	Requisito medioambiental para la adquisición de productos',
                        }, {
                            hasTexto: true,
                            texto: 'e.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            hasCategoria: true,
                            categoria: '9.0.	Operaciones - Historias - preservación ',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'a.	Entorno para tiendas - Control de material incluyendo productos químicos y derrames - Aspecto e impacto ambiental y peligro y riesgo para los procesos de las tiendas - Recepción / emisión de materiales - Artículos de vida útil - Materiales suministrados por el cliente - Despacho de productos ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        },
                        {
                            hasTexto: true,
                            texto: 'b.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles',
                        },
                        {
                            hasCategoria: true,
                            categoria: '10.0.	Operaciones de producción y prestación de servicios en Planta ',
                            auditado: '',
                            size: "6",
                            hasSubCheck: true,
                            select: [],
                            subCheck: null,
                            labelSubCheck: 'Auditoría durante el horario laboral normal ya que el cliente trabaja solo en turno de día ',
                        },
                        {
                            hasSubCheck: true,
                            labelSubCheck: `Auditoría durante el horario laboral normal, ya que los procesos son similares en cada turno.`,
                            subCheck: null,
                        }, {
                            hasSubCheck: true,
                            labelSubCheck: `Auditoría durante el turno normal y parte del turno considerando diferentes procesos en cada turno.`,
                            subCheck: null,
                        },
                        {
                            hasTexto: true,
                            texto: `a.	Planificación y control de la producción: entorno para operación del proceso - Validación del proceso - Control de cambios en el proceso`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 3,
                        },
                        {
                            hasTexto: true,
                            texto: `b.	Transporte o entrega, uso, tratamiento al final de la vida y eliminación final de los productos suministrados por proveedores externos, incluido el tratamiento al final de su vida útil, si corresponde, para los productos considerados`,
                        },
                        {
                            hasTexto: true,
                            texto: `c.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles`,
                        },
                        {
                            hasCategoria: true,
                            categoria: '11.0.	Producción de operaciones y provisión de servicios en el sitio del Proyecto, si corresponde(obligatorio para cada auditoría)',
                            auditado: '',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 2,
                            select: [],
                            hasTexto: true,
                            texto: `a.	Sitio temporal del proyecto: 
b.	Planificación y control de operaciones. 
c.	Alcance de los servicios en el sitio - Criterios para las operaciones del proceso - competencia -  Control y mantenimiento de procesos, incluidos los criterios para la operación del proceso: conocimiento de los requisitos de QHSE por parte de los trabajadores –Inspección de activos como extintores, detectores de humo y fuego, equipos y accesorios de elevación y otros
`,
                        },
                        {
                            hasTexto: true,
                            texto: `d.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles`,
                        },
                        {
                            hasCategoria: true,
                            categoria: '12.0.	Operaciones - Respuesta a emergencias',
                            auditado: '',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 2,
                            select: [],
                            hasTexto: true,
                            texto: `a.	Identificación de posibles situaciones de emergencia en la planta y tsitio temporal y plan de respuesta `,
                        },
                        {
                            hasTexto: true,
                            texto: `b.	Planifique la frecuencia de simulacros para cada situación de emergencia: Planificar la capacitación relacionada con la preparación para emergencias`,
                        },
                        {
                            hasCategoria: true,
                            categoria: '13.0.	Calidad Aseguramiento - Control de calidad',
                            auditado: '',
                            size: "4",
                            itemsText: ['', '', '', ''],
                            sizeItems: 3,
                            select: [],
                            hasTexto: true,
                            texto: `a.	Revisar el plan de QHSE / plan de inspección: revisar la interfaz con el cliente o el representante del cliente `,
                        },
                        {
                            hasTexto: true,
                            texto: `b.	Inspección entrante - inspección en proceso - inspección del producto final - liberación del producto`,
                        }, {
                            hasTexto: true,
                            texto: `c.	Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos `,
                        }, {
                            hasTexto: true,
                            texto: `d.	Aspecto ambiental, impacto, Aspecto significativo - peligro, riesgo y controles`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        },

                        {
                            hasCategoria: true,
                            categoriaVacia: true,
                            size: "1",
                            sizeItems: 1,
                            auditado: '',
                            hasTexto: true,
                            texto: 'Reunión informativa del día 1',
                            itemsText: ['', '', '', ''],
                        },
                        {
                            hasCategoria: true,
                            sinauditado: true,
                            categoria: 'Líder del equipo de auditoría de IQC',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            hasTexto: true,
                            texto: 'Los auditores se reúnen y auditan la preparación del informe por parte del equipo de auditoría.',
                        }, {
                            hasSubCheckGroup: true,
                            checkGroup: [],
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            options: [{
                                    text: 'Oficina registrada',
                                    value: '1'
                                },
                                {
                                    text: 'Planta',
                                    value: '2'
                                },
                                {
                                    text: 'Sitio',
                                    value: '3'
                                },

                            ],
                        },
                    ],
                    footer: [{
                        radio1: 2,
                        radio2: 2,
                        radio3: 2,
                        elaboradoPor: '',
                        revisadoPor: '',
                        check: [],
                        options: [{
                                text: 'SI',
                                value: '1'
                            },
                            {
                                text: 'NO',
                                value: '2'
                            },
                            {
                                text: 'N/A',
                                value: '3'
                            },

                        ],
                    }]
                }, ],
                c27001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                        input3: '',
                        input4: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: 'Alta Dirección',
                            auditado: '',
                            size: "14",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            itemsText: ['', '', '', ''],
                            sizeItems: 14
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación SGSI',
                        },
                        {
                            hasTexto: true,
                            texto: 'Requisitos legales aplicables y actualización de los requisitos',
                        },
                        {
                            hasTexto: true,
                            texto: 'Alcance del SGSI (obligatorio para cada auditoría) ',
                        }, {
                            hasTexto: true,
                            texto: 'Política y comunicación del SGSI (obligatorio para cada auditoría)',

                        }, {
                            hasTexto: true,
                            texto: 'Objetivos del SGSI y consecución de objetivoss',
                        }, {
                            hasTexto: true,
                            texto: 'Efectividad del SGSI',
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de reclamaciones legales y de clientes (obligatorio para cada auditoría)',
                        }, {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna ',
                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                        }, {
                            hasTexto: true,
                            texto: 'Recurso de mano de obra el día de la auditoría (obligatorio para cada auditoría): tiempo completo / tiempo parcial',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'CISO',
                            auditado: '',
                            size: "15",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones / OFI (Obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 4
                        },
                        {
                            hasTexto: true,
                            texto: 'Contexto de la organización y partes interesadas',

                        }, {
                            hasTexto: true,
                            texto: 'Gestión de la comunicación',
                        }, {
                            hasTexto: true,
                            texto: 'Procesos SGSI e información documentada',
                        }, {
                            hasTexto: true,
                            texto: 'Planificación - Riesgos y oportunidades - Evaluación de riesgos y tratamiento de riesgos - Eficacia de las acciones para riesgos y oportunidades - Objetivos de SI - Planificación para lograr los objetivos',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        }, {
                            hasTexto: true,
                            texto: 'Planificación de cambios al SGSI',
                        }, {
                            hasCustom1: true,
                            check1: 0,
                            check2: 0,
                            check3: 0,
                            check4: 0,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Criterios de auditoría - Alcance de la auditoría - Informar el resultado de la auditoría a la gerencia - Corrección y acción correctiva - Retención de información documentada (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Frecuencia de revisión por la dirección - Entrada de puntos de la agenda de MRM - Salida de MRM y Efectividad del sistema de gestión con respecto al logro de los objetivos y los resultados previstos (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Gestión de no conformidades producto-proceso y sistema, Quejas de clientes - Acción correctiva (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Almuerzo de trabajo 1.30 a 2.00',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI) y consecución de objetivos, corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua considerando resultado de auditoría interna-MRM- resultados de evaluación (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        }, {
                            hasTexto: true,
                            texto: 'Cumplimiento',
                        }, {
                            hasTexto: true,
                            texto: 'Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría)',
                        },
                        {
                            hasControl: true,
                            texto: `Controles IS aplicables para la cláusula A 5, A 6, A 7, A 8, A 9, A 10, A 11, A 12, A 13, A 14, A 15, A 17, A 18`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },

                        {
                            hasCategoria: true,
                            categoria: 'Recursos humanos / formación',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - conciencia (Antes del empleo y durante el empleo, terminación y cambio de empleo)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                        },

                        {
                            hasCategoria: true,
                            categoriaVacia: true,
                            size: "1",
                            hasTexto: true,
                            texto: 'Reunión informativa del día 1',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasControl: true,
                            texto: `Controles IS aplicables: A 6, A 7, A 9.3, A 17, A 18.1.1 a A 18.1.4`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Infraestructura / TI - Red',
                            auditado: '',
                            size: "2",
                            select: [],
                            hasTexto: true,
                            texto: 'Política de seguridad de la información / Dispositivo móvil y teletrabajo / Gestión de activos / Control de acceso / Criptografía / Equipos / Seguridad de operaciones / Seguridad de las comunicaciones / Adquisición de sistemas / Gestión de incidentes de seguridad de la información / Gestión de la continuidad del negocio / Cumplimiento de todos los controles aplicables a TI, Protección contra malware / volver up / seguimiento de registros / control de instalación de software operativo / vulnerabilidad técnica / eliminación de medios / gestión de capacidad / gestión de cambios / procedimientos operativos / seguridad de la red ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 2
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasControl: true,
                            texto: `Controles IS aplicables: A 5, A 6, A 8, A 9, A 10, A 11, A 12, A 13, A 14, A 16, A 17`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasControl: true,
                            texto: `Operaciones`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Contrato / Marketing',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisión de la consulta y el contrato del cliente - Identificación del contrato / trabajo - cambios en el contrato - retención de información documentada ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'Actividades posteriores a la entrega, si las hubiera. ',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Administración,Compras - Outsourcing - Gestión de tiendas',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Selección - Evaluación - reevaluación de proveedores externos - Mantenimiento de la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'Entorno para tiendas - Control de almacenamiento y recepción / emisión de material - Control de artículos de vida útil, si corresponde - Control de propiedad suministrada por el cliente (Materiales) - Retención de información documentada',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Seguro de Calidad / Control de Calidad',
                            auditado: '',
                            size: "3",
                            select: [],
                            hasTexto: true,
                            texto: 'Revisar el plan de control de calidad / plan de inspección - revisar la interfaz con el cliente o el representante del cliente - inspección entrante - inspección en proceso - inspección del producto final - liberación del producto - retención de información documentada',
                            itemsText: ['', '', '', ''],
                            sizeItems: 3
                        },
                        {
                            hasTexto: true,
                            texto: 'Calibración de recursos de monitoreo y medición - lista de instrumentos - identificación de instrumentos - retención de información documentada',
                        },
                        {
                            hasTexto: true,
                            texto: 'Objetivos funcionales (SI), corrección y acción correctiva para observaciones de auditoría, retención de información documentada',
                        },
                        {
                            hasControl: true,
                            texto: `Controles IS aplicables: A 6.2, A 8.1, A 8.3, A 9, A 10, A 11, A 12, A 14, A 15, A 16, A 17, A 18`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },

                        {
                            hasCategoria: true,
                            sinauditado: true,
                            categoria: 'Líder del equipo de auditoría de IQC',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            hasTexto: true,
                            texto: 'Los auditores se reúnen y auditan la preparación del informe por parte del equipo de auditoría.',
                        }, {
                            hasSubCheckGroup: true,
                            checkGroup: [],
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            options: [{
                                    text: 'Oficina registrada',
                                    value: '1'
                                },
                                {
                                    text: 'Planta',
                                    value: '2'
                                },
                                {
                                    text: 'Sitio',
                                    value: '3'
                                },

                            ],
                        },
                    ],
                    footer: [{
                        elaboradoPor: '',
                        revisadoPor: '',
                        check2: [],
                        check: [],
                        options: [{
                                text: 'SI',
                                value: '1'
                            },
                            {
                                text: 'NO',
                                value: '2'
                            },
                            {
                                text: 'N/A',
                                value: '3'
                            },

                        ],
                    }]
                }, ],
                c37001: [{
                    header: [{
                        check: [],
                        options: [{
                                text: 'Oficina registrada',
                                value: '1'
                            },
                            {
                                text: 'Planta',
                                value: '2'
                            },
                            {
                                text: 'Oficina (es) regional (es)',
                                value: '3'
                            },
                            {
                                text: 'Sucursal (es)',
                                value: '4'
                            }
                        ],
                        input1: '',
                        input2: '',
                        input3: '',
                        input4: '',
                    }, ],
                    body: [{
                            hasCategoria: true,
                            categoria: 'Alta Dirección',
                            auditado: '',
                            size: "8",
                            select: [],
                            hasTexto: true,
                            texto: 'Compromiso de gestión',
                            itemsText: ['', '', '', ''],
                            sizeItems: 8
                        },
                        {
                            hasTexto: true,
                            texto: 'Propósito de la certificación ABMS',
                        },
                        {
                            hasTexto: true,
                            texto: 'Efectividad de ABMS',
                        },
                        {
                            hasTexto: true,
                            texto: 'Comunicación interna y externa ',
                        }, {
                            hasTexto: true,
                            texto: 'Fiabilidad de la revisión de la gestión y la auditoría interna ',

                        }, {
                            hasTexto: true,
                            texto: 'Provisión de recursos',
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua',
                        }, {
                            hasTexto: true,
                            texto: 'Desempeño comercial: otros problemas, si corresponde',
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Función de cumplimiento anti-soborno',
                            auditado: '',
                            size: "18",
                            select: [],
                            hasTexto: true,
                            texto: 'Recursos de mano de obra el día de la auditoría ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasTexto: true,
                            texto: 'Revisión del informe de auditoría anterior y cierre de NCR (s) / Observaciones (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Alcance de ABMS (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Política y comunicación ABMS (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'ABMS Objetivos- logro de objetivos (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Auditoría interna - Planificación de la auditoría - Frecuencia de la auditoría - Criterios de auditoría - Alcance de la auditoría - Informe del resultado de la auditoría a la dirección - Corrección y acción correctiva (obligatorio para cada auditoría) ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Frecuencia de revisión por la dirección: entrada de puntos de la agenda de MRM',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: `- Salida MRM y Efectividad de la sistema de gestión con respecto al logro de los objetivos y los resultados previstos
•	Revisión de la alta dirección
•	Revisión de la junta directiva
•	Revisión de la función de cumplimiento contra el soborno
 (obligatorio para cada auditoría)
`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Revisión por la dirección (obligatoria para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Producto-proceso, no conformidad del sistema, legal y Quejas de los clientes: acciones correctivas (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Programa de mejora continua considerando resultado de auditoría interna-MRM- resultados de evaluación (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Uso de marcas de certificación y acreditación (Obligatorio para cada auditoría',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Contexto de la organización y partes interesadas',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'ABMS Procesos e información documentada',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Planificación - Riesgo y oportunidades - Evaluación del riesgo de soborno - Eficacia de las acciones para el riesgo y las oportunidades - Objetivos ABMS - Planificación para lograr los objetivos Revisión de cualquier cambio (obligatorio para cada auditoría)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Cumplimiento ',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: `Análisis de datos y evaluación del desempeño - 
a.	Análisis y evaluación de ABMS 
b.	Objetivos de ABMS
c.	Rendimiento y eficacia del ABMS 
d.	Estado de la cultura de cumplimiento
e.	Necesidad de mejorar ABMS`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        }, {
                            hasTexto: true,
                            texto: 'Manejo de documentos',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Recursos humanos / formación',
                            auditado: '',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: 'Estructura organizativa, roles, responsabilidad, autoridad - Gestión de competencias, plan de formación y cumplimiento - concienciación - Función de cumplimiento antisoborno: delegación de la toma de decisiones, según corresponda (Antes del empleo y durante el empleo, terminación y cambio de empleo)',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            total: true,
                            hasLabel: true,
                            label: 'Almuerzo de trabajo  13.30 a 14.00 horas o según los requisitos locales',
                            size: "1",
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Revisión del contrato ',
                            auditado: '',
                            size: "4",
                            hasSubCheck: true,
                            select: [],
                            subCheck: null,
                            labelSubCheck: 'Auditoría durante el horario laboral normal ya que el cliente trabaja solo en turno de día ',
                        },
                        {
                            hasSubCheck: true,
                            labelSubCheck: `Auditoría durante el horario laboral normal, ya que los procesos son similares en cada turno.`,
                            subCheck: null,
                        }, {
                            hasSubCheck: true,
                            labelSubCheck: `Auditoría durante el turno normal y parte del turno considerando diferentes procesos en cada turno.`,
                            subCheck: null,
                        },
                        {
                            hasTexto: true,
                            texto: `Revisión de la consulta y el contrato del cliente - Identificación del número de contrato / trabajo - cambios en el contrato - retención de información documentada.
Actividades posteriores a la entrega / servicio, si las hubiera.
Adecuación del mecanismo de debida diligencia adoptado.
Definición de controles financieros y no financieros.
`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        },

                        {
                            hasCategoria: true,
                            categoria: 'Administración,Compras - Outsourcing - Gestión de tiendas',
                            auditado: '',
                            size: "4",
                            select: [],
                            hasTexto: true,
                            texto: 'Selección - Evaluación - reevaluación de proveedores externos - Mantener la lista de proveedores aprobados - Subcontratación - Comunicación con agencias externas - Retención de información documentada',
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasTexto: true,
                            texto: `Entorno para tiendas - Control de almacenamiento y recepción / emisión de material - Control de artículos de vida útil, si corresponde - Control de propiedad suministrada por el cliente (Materiales) - Retención de información documentada`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        }, {
                            hasTexto: true,
                            texto: `Adecuación del mecanismo de debida diligencia adoptado.`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        }, {
                            hasTexto: true,
                            texto: `Controles financieros y no financieros definidos`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                        },
                        {
                            hasCategoria: true,
                            categoria: 'Proyecto / Diseño o Desarrollo o Ingeniería / Producción o Prestación de Servicios, según corresponda.',
                            auditado: '',
                            size: "1",
                            select: [],
                            hasTexto: true,
                            texto: `Reunión informativa del día 1
-	Revisión de los controles antisoborno 
-	Revisión de los compromisos contra el soborno
-	Revisión de las políticas de obsequios y hospitalidad
-	Revisión de políticas para donaciones, organizaciones benéficas, patrocinio, gastos de promoción y beneficios comunitarios.
-	Revisión de la gestión de la insuficiencia de los controles antisoborno
-	Planteando preocupaciones
-	Investigar y lidiar con el soborno
`,
                            itemsText: ['', '', '', ''],
                            sizeItems: 1
                        },
                        {
                            hasCategoria: true,
                            sinauditado: true,
                            categoria: 'Líder del equipo de auditoría de IQC',
                            size: "2",
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            hasTexto: true,
                            texto: 'Los auditores se reúnen y auditan la preparación del informe por parte del equipo de auditoría.',
                        }, {
                            hasSubCheckGroup: true,
                            checkGroup: [],
                            itemsText: ['', '', '', ''],
                            sizeItems: 1,
                            options: [{
                                    text: 'Oficina registrada',
                                    value: '1'
                                },
                                {
                                    text: 'Planta',
                                    value: '2'
                                },
                                {
                                    text: 'Sitio',
                                    value: '3'
                                },

                            ],
                        },
                    ],
                    footer: [{
                        radio1: 2,
                        radio2: 2,
                        radio3: 2,
                        elaboradoPor: '',
                        revisadoPor: '',
                        check2: [],
                        check: [],
                        options: [{
                                text: 'SI',
                                value: '1'
                            },
                            {
                                text: 'NO',
                                value: '2'
                            },
                            {
                                text: 'N/A',
                                value: '3'
                            },

                        ],
                    }]
                }, ],
            },

            disabledNorma1: false,
            disabledNorma2: false,
            disabledNorma3: false,
            disabledNorma4: false,
            disabledNorma5: false,
            disabledNorma6: false,

            //Listas
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            //Listas
            listaClientes: [],

            listaTipoAuditorias: [{
                    idTipoAuditoria: 2,
                    descripcion: 'Sistema de Gestión único',
                },
                {
                    idTipoAuditoria: 3,
                    descripcion: 'Auditoria conjunta',
                },
                {
                    idTipoAuditoria: 4,
                    descripcion: 'Auditoria combinada',
                },
                {
                    idTipoAuditoria: 5,
                    descripcion: 'Sistema de Gestión integrada',
                },
            ],

            tabIndex: 0,
        };

    },
    methods: {
        checkNormas(e) {
            !!e.find(x => x == 1) ? this.disabledNorma1 = true : this.disabledNorma1 = false;
            !!e.find(x => x == 2) ? this.disabledNorma2 = true : this.disabledNorma2 = false;
            !!e.find(x => x == 3) ? this.disabledNorma3 = true : this.disabledNorma3 = false;
            !!e.find(x => x == 4) ? this.disabledNorma4 = true : this.disabledNorma4 = false;
            !!e.find(x => x == 5) ? this.disabledNorma5 = true : this.disabledNorma5 = false;
            !!e.find(x => x == 6) ? this.disabledNorma6 = true : this.disabledNorma6 = false;
        },

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        avanzar() {
            this.tabIndex += 1;
        },
        anterior() {
            this.tabIndex -= 1;
        },

      
        obtenerPlanAuditoria() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/obtener-plan-auditoria-s2', {
                    params: {
                        idPlanAuditoria: me.$route.params.id,
                        idCliente: me.datosPlanAuditoria.idCliente
                    },
                })
                .then(function (response) {

                    me.datosPlanAuditoria.idPlanAuditoria = response.data[0].idPlanAuditoria;
                    me.datosPlanAuditoria.idCliente = response.data[0].idCliente;
                    me.datosPlanAuditoria.idAuditoria = response.data[0].idAuditoria;
                    me.datosPlanAuditoria.normas = JSON.parse(response.data[0].normas);
                    me.datosPlanAuditoria.sede = response.data[0].sede;
                    me.datosPlanAuditoria.planta = response.data[0].planta;
                    me.datosPlanAuditoria.contacto = response.data[0].contacto;
                    me.datosPlanAuditoria.ubicacionesSitioCliente = JSON.parse(response.data[0].ubicacionesSitioCliente);
                    me.datosPlanAuditoria.procesoAlcanceCertificacion = JSON.parse(response.data[0].procesoAlcanceCertificacion);
                    me.datosPlanAuditoria.sitioProyectoTemporal = JSON.parse(response.data[0].sitioProyectoTemporal);
                    me.datosPlanAuditoria.enfoqueAuditoria = response.data[0].enfoqueAuditoria;
                    me.datosPlanAuditoria.procesosNoAplicables = response.data[0].procesosNoAplicables;
                    me.datosPlanAuditoria.desempenoDurante = response.data[0].desempenoDurante;
                    me.datosPlanAuditoria.desempenoAuditoria = JSON.parse(response.data[0].desempenoAuditoria);
                    me.datosPlanAuditoria.auditoriaActualEsuna = JSON.parse(response.data[0].auditoriaActualEsuna);
                    me.datosPlanAuditoria.requisitosCoc = JSON.parse(response.data[0].requisitosCoc);
                    me.datosPlanAuditoria.enlaceProporciona = JSON.parse(response.data[0].enlaceProporciona);
                    me.datosPlanAuditoria.auditoriaOperaciones = JSON.parse(response.data[0].auditoriaOperaciones);
                    me.datosPlanAuditoria.traductoresInterpretes = response.data[0].traductoresInterpretes;
                    me.datosPlanAuditoria.direccionContacto = response.data[0].direccionContacto;
                    me.datosPlanAuditoria.ubicacionAuditoria = response.data[0].ubicacionAuditoria;
                    me.datosPlanAuditoria.sitioTemporalAuditado = response.data[0].sitioTemporalAuditado;
                    me.datosPlanAuditoria.nroSitios = response.data[0].nroSitios;
                    me.datosPlanAuditoria.metodologiaAuditoria = response.data[0].metodologiaAuditoria;
                    me.datosPlanAuditoria.amableAtencion = response.data[0].amableAtencion;
                    me.datosPlanAuditoria.movil = response.data[0].movil;
                    me.datosPlanAuditoria.manoObraAuditoriaAnterior = response.data[0].manoObraAuditoriaAnterior;
                    me.datosPlanAuditoria.correoElectronico = response.data[0].correoElectronico;
                    me.datosPlanAuditoria.alcanceCertificacion = response.data[0].alcanceCertificacion;

                    me.datosPlanAuditoria.tipoAuditoria = response.data[0].tipoAuditoria;
                    me.datosPlanAuditoria.eaNace = response.data[0].eaNace;
                    me.datosPlanAuditoria.anzsic = response.data[0].anzsic;
                    me.datosPlanAuditoria.cuerpoAcreditancion = response.data[0].cuerpoAcreditancion;
                    me.datosPlanAuditoria.criteriosAuditoria = response.data[0].criteriosAuditoria;
                    me.datosPlanAuditoria.auditoriaActual = response.data[0].auditoriaActual;
                    me.datosPlanAuditoria.auditoriaAnterior = response.data[0].auditoriaAnterior;
                    me.datosPlanAuditoria.elCertificadoCumplimiento = response.data[0].elCertificadoCumplimiento;
                    me.datosPlanAuditoria.totalDiasHombre = response.data[0].totalDiasHombre;
                    me.datosPlanAuditoria.fechaNotificacionAuditoria = response.data[0].fechaNotificacionAuditoria;
                    me.datosPlanAuditoria.notificacionParaAuditoriaRemota = JSON.parse(response.data[0].notificacionParaAuditoriaRemota);
                    me.datosPlanAuditoria.planificacionTiempoAuditoria = JSON.parse(response.data[0].planificacionTiempoAuditoria);
                    me.datosPlanAuditoria.procesosProduccionServicio = JSON.parse(response.data[0].procesosProduccionServicio);
                    me.datosPlanAuditoria.procesosActividadesCubiertos = response.data[0].procesosActividadesCubiertos;
                    me.datosPlanAuditoria.reunionAperturaAuditoria = response.data[0].reunionAperturaAuditoria;
                    me.datosPlanAuditoria.reunionCierreAuditoria = response.data[0].reunionCierreAuditoria;
                    me.datosPlanAuditoria.auditorTlms = response.data[0].auditorTlms;
                    me.datosPlanAuditoria.auditor2 = response.data[0].auditor2;
                    me.datosPlanAuditoria.auditor3 = response.data[0].auditor3;
                    me.datosPlanAuditoria.auditorFormacion = response.data[0].auditorFormacion;
                    me.datosPlanAuditoria.ao = response.data[0].ao;
                    me.datosPlanAuditoria.te = response.data[0].te;
                    me.datosPlanAuditoria.observadorAuditoria = response.data[0].observadorAuditoria;
                    me.datosPlanAuditoria.c9001 = JSON.parse(response.data[0].c9001);
                    me.datosPlanAuditoria.c45001 = JSON.parse(response.data[0].c45001);
                    me.datosPlanAuditoria.c14001 = JSON.parse(response.data[0].c14001);
                    me.datosPlanAuditoria.c27001 = JSON.parse(response.data[0].c27001);
                    me.datosPlanAuditoria.c37001 = JSON.parse(response.data[0].c37001);
               
                    !!me.datosPlanAuditoria.normas.find(x => x == 1) ? me.disabledNorma1 = true : me.disabledNorma1 = false;
                    !!me.datosPlanAuditoria.normas.find(x => x == 2) ? me.disabledNorma2 = true : me.disabledNorma2 = false;
                    !!me.datosPlanAuditoria.normas.find(x => x == 3) ? me.disabledNorma3 = true : me.disabledNorma3 = false;
                    !!me.datosPlanAuditoria.normas.find(x => x == 4) ? me.disabledNorma4 = true : me.disabledNorma4 = false;
                    !!me.datosPlanAuditoria.normas.find(x => x == 5) ? me.disabledNorma5 = true : me.disabledNorma5 = false;
                    !!me.datosPlanAuditoria.normas.find(x => x == 6) ? me.disabledNorma6 = true : me.disabledNorma6 = false;
               
                if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosPlanAuditoria.isPack = true;
                        me.datosPlanAuditoria.idPlanAuditoria = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosPlanAuditoria.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosPlanAuditoria.idUsuario = me.$store.state.user.uid;
                        me.datosPlanAuditoria.sUsuario = me.$store.state.user.username;
                        me.datosPlanAuditoria.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
               })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' );
                     me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        registrarPlanAuditoria() {
            let me = this;
            me.disabled = true;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/registrar-plan-auditoria-s2',
                    me.datosPlanAuditoria, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`,
                        },
                    })
                .then(function (response) {
                    let color = response.data.resultado == 1 ? 'success' : 'error';
                    me.swat(color, response.data.mensaje);
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');

                });
            me.disabled = false;
            me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
        },

        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosPlanAuditoria.idCliente = user.uidClient;
            // this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerPlanAuditoria();
            }
        }
    },
};
</script>

<style>
.disabledTab {
    pointer-events: none;
    /* background:rgba(37, 37, 37, 0.048); */
}

.valign-middle {
    vertical-align: middle !important;
}

/* 
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {

cursor: not-allowed !important;

background-color: #ffffff !important;
} */
</style>
